import { waitForLoaderToHide } from "../utils/scene.utils";
import ErrorDialog from "../../vue/components/error-dialog.component.vue";
import { addVueComponent, removeVueComponent } from "../utils/vue.utils";

AFRAME.registerComponent("error-scene-controller", {
  events: {
    sceneEnter: function () {
      this.el.sceneEl.emit("activateStaticCamera", {
        width: 5,
        height: 5,
      });

      this.el.sceneEl.emit("clearBackLink");
      this.el.sceneEl.systems["loader"].clear();
      waitForLoaderToHide();

      this.elErrorDialog = addVueComponent(ErrorDialog);
    },

    sceneExit: function () {
      removeVueComponent(this.elErrorDialog);
    },

    gotoMainMenu: function () {
      this.el.sceneEl.systems["router"].navigate([""]);
    },

    refresh: function () {
      window.location.reload();
    },
  },
});
