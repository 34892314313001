AFRAME.registerComponent("rig-transform", {
  schema: {
    animation: { type: "string" },
    position: { type: "vec3" },
    rotation: { type: "vec3" },
  },

  update: function () {
    const { animation, position, rotation } = this.data;
    position.y += 1.6;

    if (animation) {
      this.animateCameraTransform(position, rotation, animation);
    } else {
      this.setCameraTransform(position, rotation);
    }
  },

  animateCameraTransform(position, rotation, animation) {
    animation = JSON.parse(animation);

    this.setAnimation("position", position, animation);
    this.setAnimation("rotation", rotation, animation);
  },

  setAnimation(property, to, animation) {
    this.el.setAttribute(`animation__${property}`, {
      ...animation,
      property,
      to,
    });
  },

  setCameraTransform(position, rotation) {
    this.el.setAttribute("position", position);
    this.el.setAttribute("rotation", rotation);
  },
});
