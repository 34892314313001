import { waitForLoaderToHide } from "../utils/scene.utils";
import { getState } from "../../vue/utils/aframe.utils";

AFRAME.registerComponent("placeholder-scene-controller", {
  events: {
    sceneEnter: function () {
      if (getState().isProductionEnvironment) {
        this.el.sceneEl.systems["navigate-to-scene"].transition([""]);
        return;
      }

      this.el.sceneEl.emit("activateOrbitalCamera", {
        duration: 1,
        minDistance: 4,
        maxDistance: 100,
        target: "0 0 -7",
      });
      waitForLoaderToHide();
    },
  },
});
