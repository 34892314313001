import { getState } from "../../vue/utils/aframe.utils";

AFRAME.registerComponent("attach-to-vr-rig", {
  schema: {
    position: { type: "vec3" },
  },

  init: function () {
    const { x, y, z } = this.data.position;
    const { isIOS } = getState();
    this.el.object3D.parent = document.querySelector(
      "#camera-virtual-reality"
    ).object3D;
    this.el.object3D.position.set(x, y - (isIOS ? 1.6 : 0), z);
  },
});
