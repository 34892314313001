var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "scene-information",
      class: { enabled: _vm.sceneInformation.topic !== null },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "icons_container" }, [
          _c(
            "div",
            {
              class: {
                currentTab: _vm.sceneInformation.topic === "introduction",
              },
              on: {
                click: function ($event) {
                  return _vm.toggleTopicInsideMobileView("introduction")
                },
              },
            },
            [_c("img", { attrs: { src: "/images/ui/info-icon.png" } })]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: {
                currentTab: _vm.sceneInformation.topic === "didYouKnow",
              },
              on: {
                click: function ($event) {
                  return _vm.toggleTopicInsideMobileView("didYouKnow")
                },
              },
            },
            [_c("img", { attrs: { src: "/images/ui/question-mark-icon.png" } })]
          ),
        ]),
        _vm._v(" "),
        _vm.sceneInformation.topic === "introduction"
          ? _c("div", { staticClass: "introduction" }, [
              _c("h1", [
                _vm._v(_vm._s(_vm.sceneInformation.introduction.title)),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm.sceneInformation.introduction.content)),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.sceneInformation.topic === "didYouKnow"
          ? _c("div", { staticClass: "did-you-know" }, [
              _c("h1", [_vm._v(_vm._s(_vm.sceneInformation.didYouKnow.title))]),
              _vm._v(" "),
              _c("p", [
                _vm._v(_vm._s(_vm.sceneInformation.didYouKnow.content)),
              ]),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }