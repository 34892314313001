import { getState } from "../../vue/utils/aframe.utils";

AFRAME.registerComponent("scene-information", {
  schema: { type: "string" },

  update: function () {
    if (this.entity) {
      this.el.removeChild(this.entity);
      this.entity = null;
    }

    const sceneInformation = getState().sceneInformation[this.data];
    if (!sceneInformation) {
      return;
    }

    const { content, title } = sceneInformation;

    if (!content && !title) {
      return;
    }

    const entity = document.createElement("a-entity");
    entity.setAttribute("bind__visible", "userIsInVR");
    entity.setAttribute("dialog-box", {
      align: "top",
      attachToVrRig: false,
      setVisible: false,
    });

    const elDialogBox = document.createElement("a-entity");
    elDialogBox.setAttribute("class", "dialog-box");
    elDialogBox.setAttribute("data-raycastable-vr", "");
    elDialogBox.setAttribute("do-not-animate-cursor", "");
    elDialogBox.setAttribute("geometry", {
      primitive: "plane",
      width: 4,
      height: 1,
    });
    elDialogBox.setAttribute("material", { color: "black", shader: "flat" });
    elDialogBox.setAttribute("position", { x: 0, y: 0, z: 1 });

    const elText = document.createElement("a-entity");
    elText.setAttribute("position", { x: 0, y: 0, z: 0.01 });
    elText.setAttribute("app-text", {
      align: "language",
      baseline: "top",
      scaleGeometry: true,
      value: `${title}\n\n${content}`,
      maxWidth: 3.7,
    });
    elDialogBox.appendChild(elText);

    entity.appendChild(elDialogBox);
    this.el.appendChild(entity);

    this.entity = entity;
  },
});
