import { getState } from "../../vue/utils/aframe.utils";
import { getOrbitControls } from "../utils/camera.utils";

const cameraPos = new THREE.Vector3();
const worldPos = new THREE.Vector3();
AFRAME.registerComponent("marker", {
  schema: {
    scale: { type: "vec3" },
  },

  tick: function () {
    if (getState().userIsInVR) {
      const pos = this.el.sceneEl.systems.state.state.vrCameraPosition;
      cameraPos.set(pos.x, pos.y + 1.6, pos.z);
    } else {
      cameraPos.copy(getOrbitControls().object3D.children[0].position);
    }

    this.el.object3D.getWorldPosition(worldPos);
    const distance = cameraPos.distanceTo(worldPos);
    const scale = distance * 0.0125;
    const annotationScale = this.data.scale;
    this.el.object3D.scale.set(
      scale / annotationScale.x,
      scale / annotationScale.y,
      scale / annotationScale.z
    );
  },
});
