<template>
  <div class="cardboard-mode-instructions">
    <h2>Rotate your phone and insert it in your cardboard</h2>
    <img @click="exit()" src="/images/ui/exit-button.png" />
  </div>
</template>

<script>
export default {
  methods: {
    exit() {
      const scene = document.querySelector("a-scene");
      Array.from(
        document.querySelectorAll("#vue .components, #vue-ui")
      ).forEach((elVue) => elVue.classList.remove("user-is-in-vr"));
      scene.emit("disableCardboardMode");
    },
  },
};
</script>

<style lang="scss">
body {
  .cardboard-mode-instructions {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    background-color: black;

    h2 {
      position: relative;
      float: left;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      text-align: center;
    }

    img {
      position: fixed;
      top: 15px;
      left: 15px;
      width: 10vw;
    }
  }
}

@media (orientation: portrait) {
  body.cardboard-mode {
    .cardboard-mode-instructions {
      display: inherit;
    }
  }
}
</style>
