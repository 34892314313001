var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header-component" },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "menu-back-button" }, [
            _vm.hasBackLink
              ? _c("img", {
                  staticClass: "exit",
                  attrs: { src: "/images/header/backico.png" },
                  on: { click: _vm.navigateBack },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "menu-header" }, [
            _c("img", {
              staticClass: "bar",
              attrs: { src: _vm.logoChange },
              on: { click: _vm.navigateHome },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "menu-toggle" }, [
            _c("img", {
              staticClass: "menu-btn",
              class: {
                inactive: !_vm.mobileMenuActive,
                active: _vm.mobileMenuActive,
              },
              attrs: { src: "/images/header/Menu.png" },
              on: {
                click: function ($event) {
                  return _vm.openMenu()
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("MobileMenu", {
        staticClass: "mobile-menu",
        class: { visible: _vm.mobileMenuActive },
        on: { close: _vm.closeMenu },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }