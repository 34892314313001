<template>
  <div
    class="scene-information"
    :class="{ enabled: sceneInformation.topic !== null }"
  >
    <div class="container">
      <div class="icons_container">
        <div
          :class="{ currentTab: sceneInformation.topic === 'introduction' }"
          @click="toggleTopicInsideMobileView('introduction')"
        >
          <img src="/images/ui/info-icon.png" />
        </div>
        <div
          :class="{ currentTab: sceneInformation.topic === 'didYouKnow' }"
          @click="toggleTopicInsideMobileView('didYouKnow')"
        >
          <img src="/images/ui/question-mark-icon.png" />
        </div>
      </div>
      <div
        v-if="sceneInformation.topic === 'introduction'"
        class="introduction"
      >
        <h1>{{ sceneInformation.introduction.title }}</h1>
        <p>{{ sceneInformation.introduction.content }}</p>
      </div>
      <div v-if="sceneInformation.topic === 'didYouKnow'" class="did-you-know">
        <h1>{{ sceneInformation.didYouKnow.title }}</h1>
        <p>{{ sceneInformation.didYouKnow.content }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getScene, getState } from "../utils/aframe.utils";

export default {
  data: () => ({
    sceneInformation: { ...getState().sceneInformation },
  }),
  created() {
    getScene().addEventListener("disableSceneInformationTopic", this.onToggle);
    getScene().addEventListener("toggleSceneInformationTopic", this.onToggle);
  },
  destroyed() {
    getScene().removeEventListener(
      "disableSceneInformationTopic",
      this.onToggle
    );
    getScene().removeEventListener(
      "toggleSceneInformationTopic",
      this.onToggle
    );
  },
  methods: {
    onToggle: function () {
      setTimeout(() => {
        this.sceneInformation = { ...getState().sceneInformation };
      });
    },
    toggleTopicInsideMobileView(topic) {
      // View shouldn't not close inside mobile view when clicking it again
      if (this.sceneInformation.topic !== topic) {
        getScene().emit("toggleSceneInformationTopic", topic);
      }
    },
  },
};
</script>

<style lang="scss">
.scene-information {
  position: fixed;
  top: 190px;
  width: 100vw;
  z-index: 20;
  display: none;
  font-family: "Lato", "Helvetica Neue";
  justify-content: center;

  &.enabled {
    display: flex;
  }

  .container {
    color: white;
    width: 80%;
    max-width: 560px;
    padding: 20px;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.895);

    .icons_container {
      display: none;
    }
  }

  h1 {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 300;
  }
}

@media screen and (height: 1366px) {
  .scene-information {
    top: 245px;
  }
}
@media screen and (max-width: 800px) {
  .scene-information {
    top: 230px;
  }
}
@media screen and (max-width: 680px) {
  .scene-information {
    top: 25%;
    max-height: 490px;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: unset;
      margin-bottom: 15px;
      width: 92%;
      overflow-y: scroll;

      .img_enable {
        display: block;
      }
      .introduction {
        margin-top: 4%;
        padding: 0px 25px 25px 25px;
      }
      .did-you-know {
        margin-top: 4%;
        padding: 0px 25px 25px 25px;
      }
      img {
        width: 13%;
      }
      .icons_container {
        width: 100%;
        display: flex;

        div {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 18px 0px;
          background: linear-gradient(
            180deg,
            rgba(31, 31, 31, 1) 0%,
            rgba(0, 0, 0, 1) 100%
          );
          img {
            width: 14%;
          }
        }
        .currentTab {
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(84, 84, 84, 1) 100%
          );
          border-radius: 15px;
        }
      }
    }
    h1 {
      font-size: 16px;
    }
  }
}
@media screen and (height: 480px) {
  .scene-information {
    max-height: 355px !important ;
  }
}
@media screen and (max-width: 365px) {
  .scene-information {
    top: 27%;
    max-height: 419px;
  }
}
@media screen and (width: 1366px) and (orientation: landscape) {
  .scene-information {
    top: 220px;
  }
}
@media screen and (max-width: 827px) and (orientation: landscape) {
  .scene-information {
    position: absolute;
    overflow-y: scroll;
    top: 135px !important;

    .container {
      padding: 15px;
      width: 100%;

      .icons_container {
        display: none;
      }

      img {
        width: 7%;
      }
      .introduction {
        margin-top: 2%;
        border: none;
        padding: 0px !important;
      }
      .did-you-know {
        padding: 0px !important;
      }
    }
    h1 {
      font-size: 16px;
    }
  }
}
@media screen and (max-width: 680px) and (orientation: landscape) {
  .scene-information {
    .container {
      max-width: 507px !important;
      overflow-y: unset;
    }
  }
}
@media screen and (max-width: 568px) and (orientation: landscape) {
  .scene-information {
    max-height: 210px;
    top: 110px !important;

    .container {
      max-width: 482px !important;
    }
  }
}
@media screen and (width: 480px) and (orientation: landscape) {
  .scene-information {
    .container {
      padding: 9px 20px;
      max-width: 401px !important;
    }
    h1 {
      font-size: 14px !important;
    }
  }
}
body.rtl {
  .scene-information,
  .breadcrumbs,
  .main-menu,
  #vue .components .dialog-box-vue,
  .loader-component .text,
  .menu {
    direction: rtl;
  }
}
</style>
