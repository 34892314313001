AFRAME.registerSystem("device", {
  init: function () {
    const mouseMoveEvent = () => {
      this.el.emit("mouseDetected");
      document.removeEventListener("mousemove", mouseMoveEvent);
    };

    document.addEventListener("mousemove", mouseMoveEvent);
  },
});
