<template>
  <carousel class="app-carousel" :loop="true" :perPage="1" ref="carousel">
    <slide v-for="(item, index) in items" v-bind:key="index">
      <CarouselItem :src="item.image" @click="navigateTo(item.scene)" />
      <h1 v-if="item.title" class="card_title">{{ item.title }}</h1>
      <h2 v-if="item.subtitle">{{ item.subtitle }}</h2>
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import CarouselItem from "./carousel-item.component.vue";
import { transitionTo } from "../utils/aframe.utils";
import { getAsset } from "../../aframe/utils/assets.utils";

export default {
  components: {
    Carousel,
    CarouselItem,
    Slide,
  },
  props: {
    items: { type: Array, default: () => [] },
  },
  data: () => ({
    carouselItems: [],
  }),
  async created() {
    this.draggingInterval = setInterval(this.checkDragging);

    await Promise.all(this.items.map((item) => getAsset(item.image)));
    this.carouselItems = this.items;
  },
  destroyed() {
    clearInterval(this.draggingInterval);
  },
  methods: {
    checkDragging() {
      const { dragging } = this.$refs.carousel;
      setTimeout(() => (this.dragging = dragging), 500);
    },
    navigateTo(commands) {
      if (this.dragging) {
        return;
      }

      transitionTo(commands);
    },
  },
};
</script>

<style lang="scss">
.app-carousel {
  h1 {
    font-size: 18px;
  }

  h2 {
    font-size: 14px;
  }

  h1,
  h2 {
    color: white;
    text-align: center;
  }
  .card_title {
    position: relative;
    bottom: 10%;
    margin: 0;

    @media screen and (height: 812px) {
      bottom: 8.5%;
    }
  }
  .VueCarousel-pagination {
    margin-bottom: 2%;
  }
  .VueCarousel-dot-container {
    margin: 0 !important;

    .VueCarousel-dot {
      margin: 0 !important;
      padding: 0px 10px !important;
    }
  }
  @media screen and (max-device-width: 375px) {
    h1 {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 900px) and (orientation: landscape) {
    h1 {
      font-size: 12px;
      margin: 0;
    }
    .card_title {
      bottom: 6.5%;
      margin: 0;
    }
  }
  @media screen and (max-width: 568px) and (orientation: landscape) {
    h1 {
      font-size: 11px;
    }
  }
}
</style>
