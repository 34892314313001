<template>
  <div
    class="main-menu-button"
    :style="style"
    @click="onClick"
    :class="{ 'reverse-content': selectedLanguage == 'ar' }"
  >
    <div class="text">
      <h1>{{ title }}</h1>
    </div>
    <img
      :style="iconStyle"
      class="icon"
      :src="icon"
      :class="{ 'ar-icons': selectedLanguage == 'ar' }"
      crossorigin=""
    />
  </div>
</template>

<script>
import { getSelectedLanguage } from "../../aframe/utils/local-storage.utils";
export default {
  props: {
    disabled: Boolean,
    id: String,
    title: String,
    iconWidth: String,
    iconPosition: {
      type: String,
      default: "0",
    },
  },
  data: () => ({
    selectedLanguage: getSelectedLanguage(),
  }),
  computed: {
    icon() {
      return `/images/main-menu/icon-${this.id}.png`;
    },
    style() {
      return `background-image: url('/images/main-menu/bg-${this.id}.png');`;
    },
    iconStyle() {
      return `width: ${this.iconWidth}; left: ${this.iconPosition}`;
    },
  },
  methods: {
    onClick: function () {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss">
.main-menu-button {
  background-size: 100% 100%;
  color: white;
  height: 110px;
  cursor: pointer;
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    overflow: hidden;
  }

  img {
    width: 100%;

    &.icon {
      position: relative;
      float: right;
      height: auto;
      width: 120px;
    }
  }

  .text {
    margin: 0px 10px;
  }
}
.menuButton_conatiner1 {
  .main-menu-button {
    &:nth-child(2),
    &:nth-child(3) {
      img {
        &.ar-icons {
          left: -13px !important;
        }
      }
    }
  }
}
.menuButton_container2 {
  .main-menu-button {
    &:nth-child(1) {
      img {
        &.ar-icons {
          left: 14px !important;
        }
      }
    }
    &:nth-child(2) {
      img {
        &.ar-icons {
          left: -16px !important;
        }
      }
    }
  }
}
@media screen and (min-width: 420px) and (orientation: landscape) {
  .main-menu-button {
    height: 100px !important;
    .text {
      width: 100%;
      margin-left: 11px;
      text-align: left;
      h1 {
        font-size: 16px;
      }
    }
    img {
      &.icon {
        width: 35%;
        object-fit: contain;
      }
    }
  }
  .reverse-content {
    flex-direction: row-reverse;
  }
  .menuButton_conatiner1 {
    .main-menu-button {
      &:nth-child(2) {
        img {
          &.icon {
            left: 25px !important;
          }
        }
      }
      &:nth-child(3) {
        img {
          &.icon {
            left: 10px !important;
          }
        }
      }
    }
  }
  .menuButton_container2 {
    .main-menu-button {
      .text {
        text-align: right;
        order: 2;
        margin-right: 11px;
        margin-left: 5px;
      }
      &:nth-child(1) {
        img {
          &.icon {
            left: 10px !important;
          }
        }
      }
      &:nth-child(2) {
        img {
          &.icon {
            left: -16px !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 669px) and (orientation: landscape) {
  .main-menu-button {
    height: 80px !important;

    .text {
      h1 {
        font-size: 13px !important;
      }
    }
  }
  .menuButton_conatiner1,
  .menuButton_container2 {
    .main-menu-button {
      &:nth-child(1) {
        img {
          &.icon {
            width: 54px !important;
          }
        }
      }
      &:nth-child(2) {
        img {
          &.icon {
            width: 85px !important;
          }
        }
      }
      &:nth-child(3) {
        img {
          &.icon {
            width: 75px !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 480px) and (orientation: landscape) {
  .main-menu-button {
    height: 66px !important;
    .text {
      h1 {
        font-size: 10px !important;
      }
    }
  }
}
</style>
