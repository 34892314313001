<template>
  <div class="loader-component">
    <div class="container" v-bind:class="{ visible: visible }">
      <div
        class="loader"
        v-bind:class="{
          'rotation-90': rotation === 1,
          'rotation-180': rotation === 2,
          'rotation-270': rotation === 3,
        }"
      >
        <img
          v-bind:class="{ hidden: cornerVisible }"
          class="center"
          src="/images/loader/loader-center.png"
        />
        <img
          v-bind:class="{ hidden: !cornerVisible }"
          src="/images/loader/loader-corner.png"
        />
      </div>
    </div>
    <div class="text" v-bind:class="{ visible: showText && visible }">
      <h1>{{ loaderText.title }}</h1>
      <p>{{ loaderText.content }}</p>
    </div>
  </div>
</template>

<script>
import { getState, getGenericData } from "../utils/aframe.utils";

export default {
  data: () => ({
    cornerVisible: true,
    rotation: 0,
    showLoader: false,
    showText: false,
    visible: false,
    loaderText: {
      title: "",
      content: "",
    },
  }),
  async created() {
    const { loaderText } = await getGenericData();
    this.loaderText = loaderText;
  },
  mounted() {
    const scene = document.querySelector("a-scene");
    scene.addEventListener("showLoader", () => {
      const state = getState();

      this.showLoader = true;
      this.showText = state ? state.showLoaderText : false;
      this.visible = true;
    });
    scene.addEventListener("hideLoader", () => {
      this.showLoader = false;
      this.visible = false;
    });

    setInterval(() => {
      this.cornerVisible = !this.cornerVisible;

      if (this.cornerVisible) {
        this.rotation++;
        if (this.rotation > 3) {
          this.rotation = 0;
        }
      }
    }, 75);
  },
};
</script>

<style lang="scss" scoped>
.loader-component {
  pointer-events: none;
}

.container {
  position: fixed;
  top: 50%;
  left: 0;
  transition: opacity 1.5s;
  transition-delay: 2.5s;
  opacity: 0;
  transform: translateY(-5vw);

  &.visible {
    opacity: 1;
  }

  width: 100%;
}

.loader {
  width: 7vw;
  margin: auto;

  img {
    width: 100%;

    &.hidden {
      position: fixed;
      left: -1000px;
      width: 0;
    }
  }

  &.rotation-90 {
    transform: rotateZ(90deg);
  }

  &.rotation-180 {
    transform: rotateZ(180deg);
  }

  &.rotation-270 {
    transform: rotateZ(270deg);
  }
}

.text {
  position: fixed;
  top: calc(50% + 6vw);
  left: 0;
  color: white;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: opacity 0s;
  transition-delay: 0s;

  &.visible {
    transition: opacity 1s;
    transition-delay: 3s;
    opacity: 1;
  }
}

@media screen and (max-width: 640px) {
  .loader {
    width: 20vw;
    margin: auto;
  }
}
</style>
