var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.imageData
    ? _c("img", {
        staticClass: "carousel-item",
        attrs: { src: _vm.imageData },
        on: {
          click: function ($event) {
            return _vm.onClick()
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }