var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "toggle-volume" }, [
    _c("img", {
      attrs: { src: "/images/header/volume.png" },
      on: {
        click: function ($event) {
          return _vm.emit()
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }