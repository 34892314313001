var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "enter-ar-button" } }, [
    _c("img", {
      attrs: { src: "/images/ui/ar-button.png" },
      on: {
        click: function ($event) {
          return _vm.onClick()
        },
      },
    }),
    _vm._v(" "),
    _c(
      "a",
      {
        ref: "quickLookLink",
        staticClass: "quick-look-utils",
        attrs: { download: "asset.usdz", rel: "ar" },
      },
      [_c("img")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }