import { getState } from "../../vue/utils/aframe.utils";
import { isMobioVr } from "../../vue/utils/isMobioVr.utils";

export function transformData(data) {
  // This function takes in the json data file and
  // returns data for the language set in state

  // TODO: Set this from state
  const language = getState().selectedLanguage;
  const lang = language ? language.id : "en";

  function objMap(obj, func) {
    return Object.fromEntries(
      Object.entries(obj).map(([k, v]) => [k, func(v, k)])
    );
  }

  const mappedObj = objMap(data, (value, key) => {
    if (
      typeof value === "object" &&
      value !== null &&
      Object.prototype.hasOwnProperty.call(value, "en")
    ) {
      return value[lang];
    } else if (key === "introduction") {
      return isMobioVr ? value["mobioVR"][lang] : value["xrAcademy"][lang];
    } else if (key === "homeCenterImage") {
      return isMobioVr ? value["mobioVR"][lang] : value["xrAcademy"][lang];
    } else if (key === "menu") {
      return value.map((item) => {
        return {
          thumbnail_text: item.thumbnail_text[lang],
          scene: item.scene,
          thumbnail: item.thumbnail,
        };
      });
    } else if (key === "annotations") {
      return {
        nodes: value.nodes.map((item) => {
          return {
            title: item.text[lang]["title"],
            content: item.text[lang]["content"],
            position: item.position,
            rotation: item.rotation,
          };
        }),
        scale: value.scale,
        rotation: value.rotation,
        position: value.position,
      };
    }
    return value;
  });
  return mappedObj;
}
