import { findNearestObject3dByType } from "../utils/three-js.utils";
import { getState } from "../../vue/utils/aframe.utils";
import { determineLanguageAlignment } from "../utils/i18n.utils";

const fonts = {
  FreeSans: "/fonts/FreeSans/FreeSansBold.otf",
  Scheherazade: "/fonts/Scheherazade/Scheherazade-Bold.ttf",
  ThaiSarbun: "/fonts/ThaiSarabun/ThaiSarabunRegular.ttf ",
};

AFRAME.registerComponent("app-text", {
  schema: {
    align: {
      type: "string",
      oneOf: ["left", "center", "right", "language"],
      default: "center",
    },
    anchor: {
      type: "string",
      oneOf: ["left", "center", "right"],
      default: "center",
    },
    baseline: {
      type: "string",
      oneOf: ["top", "center", "bottom"],
      default: "center",
    },
    color: { type: "string", default: "white" },
    font: { type: "string", oneOf: Object.keys(fonts), default: "FreeSans" },
    fontSize: { type: "number", default: 0.15 },
    maxWidth: { type: "number", default: Infinity },
    scaleGeometry: { type: "boolean", default: false },
    showLoader: { type: "boolean", default: false },
    value: { type: "string", default: "" },
  },

  init: function () {
    this.waitForRender = this.waitForRender.bind(this);
  },

  update: function () {
    this.remove();

    const { align, baseline, font, scaleGeometry, showLoader } = this.data;
    const { selectedLanguage } = getState();

    const config = {
      ...this.data,
      align:
        align === "language"
          ? determineLanguageAlignment(selectedLanguage.rtl)
          : align,
      baseline: scaleGeometry ? "right" : baseline,
      direction: selectedLanguage.rtl ? "rtl" : "ltr",
      font: fonts[selectedLanguage.fontOverride || font],
    };
    delete config.scaleGeometry;
    delete config.showLoader;

    this.el.setAttribute("troika-text", config);

    if (showLoader) {
      this.el.sceneEl.systems.loader.addIdForLoader(this.el);
    }

    if (scaleGeometry) {
      this.getGeometryObject().visible = false;
    }

    this.waitForRenderInterval = setInterval(this.waitForRender, 10);
  },

  remove: function () {
    clearInterval(this.waitForRenderInterval);
    this.el.sceneEl.systems.loader.removeIdForLoader(this.el);
    this.el.removeAttribute("troika-text");
  },

  waitForRender: function () {
    const text = findNearestObject3dByType(this.el.object3D, "Mesh");
    const { x } = text.geometry.boundingBox.max;

    if (Math.abs(x) === Infinity) {
      return;
    }

    clearInterval(this.waitForRenderInterval);

    const { scaleGeometry, showLoader } = this.data;
    if (showLoader) {
      this.el.sceneEl.systems.loader.removeIdForLoader(this.el);
    }

    if (scaleGeometry) {
      setTimeout(() => this.scaleGeometry());
    }
  },

  scaleGeometry: function () {
    const text = findNearestObject3dByType(this.el.object3D, "Mesh");
    const y = text.geometry.boundingBox.max.y * 2 + 0.025;

    if (Math.abs(y) === Infinity) {
      setTimeout(() => this.scaleGeometry());
      return;
    }

    if (this.data.baseline === "top") {
      this.el.object3D.parent.position.setY(
        this.el.object3D.parent.position.y - y
      );
    }

    const geometryHeight = y * 1.15;

    this.el.object3D.parent.scale.setY(geometryHeight);
    this.el.object3D.scale.setY(1 / geometryHeight);

    this.el.emit("scaleGeometry", { height: geometryHeight }, true);
    this.getGeometryObject().visible = true;
  },

  getGeometryObject: function () {
    return this.el.object3D.parent;
  },
});
