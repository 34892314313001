<template>
  <div class="model-menu">
    <p>{{ startExperienceText }}</p>
    <div
      v-for="(item, index) in items"
      v-bind:key="index"
      class="view-in-3D"
      @click="navigateTo(item.scene)"
    >
      <img
        :class="{ disabled: item.scene === null }"
        :src="item.image"
        crossorigin=""
      />
      <h1 v-if="item.title">{{ item.title }}</h1>
    </div>
  </div>
</template>

<script>
import { transitionTo, getGenericData } from "../utils/aframe.utils";
import { getAsset } from "../../aframe/utils/assets.utils";

export default {
  props: {
    items: { type: Array, default: () => [] },
  },
  data: () => ({
    item: [],
    startExperienceText: null,
  }),
  async created() {
    await Promise.all(this.items.map((item) => getAsset(item.image)));
    this.item = this.items;
    const { startExperienceText } = await getGenericData();
    this.startExperienceText = startExperienceText;
  },
  methods: {
    navigateTo(commands) {
      if (this.dragging) {
        return;
      }

      transitionTo(commands);
    },
  },
};
</script>

<style lang="scss">
.model-menu {
  color: white;

  .view-in-3D {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 35%;
  }
  p {
    text-align: center;
    font-size: 18px;
    margin: 0 auto 6%;
    width: 70vw;
  }
  img {
    max-height: 60vh;
    max-width: 70vw;
    height: auto;
    width: 80vw;
  }

  h1 {
    position: absolute;
    font-size: 20px;
    margin: 0;
  }

  h1,
  h2 {
    color: white;
    text-align: center;
  }
  @media screen and (max-width: 320px) {
    p {
      width: 84vw;
    }
  }
  @media screen and (max-width: 900px) and (orientation: landscape) {
    margin-top: 7% !important;
    p {
      margin-bottom: 4%;
    }
    img {
      width: 45vw;
      margin-top: 0;
    }
  }
  @media screen and (max-width: 1366px) and (orientation: landscape) {
    margin-top: 52%;

    p {
      margin-bottom: 1%;
    }
    img {
      width: 37vw;
    }
  }
  @media screen and (max-width: 568px) and (orientation: landscape) {
    h1 {
      font-size: 16px;
    }

    h1,
    h2 {
      color: white;
      text-align: center;
    }
  }
}
</style>
