<template>
  <div id="enter-ar-button">
    <img src="/images/ui/ar-button.png" @click="onClick()" />
    <a
      class="quick-look-utils"
      download="asset.usdz"
      rel="ar"
      ref="quickLookLink"
    >
      <img />
    </a>
  </div>
</template>

<script>
import { getState } from "../utils/aframe.utils";
import "../utils/usdz-exporter.utils";

export default {
  props: {
    href: { type: String },
    object3D: { type: Object },
  },
  methods: {
    onClick() {
      const { arMode } = getState();
      if (!arMode) {
        return;
      }

      const asset = this.object3D.clone();
      asset.position.set(0, 0, 0);
      asset.scale.setScalar(1);

      const boundingBox = new THREE.Box3().setFromObject(asset);
      const scale = Math.max(
        Math.abs(boundingBox.min.x) + Math.abs(boundingBox.max.x),
        Math.abs(boundingBox.min.y) + Math.abs(boundingBox.max.y),
        Math.abs(boundingBox.min.z) + Math.abs(boundingBox.max.z)
      );
      asset.scale.setScalar(1 / scale);

      new THREE.Box3().setFromObject(asset);

      if (arMode === "quicklook") {
        this.showModelViaQuicklook(asset);
      } else if (arMode === "webxr") {
        this.showModelViaSceneViewer(asset);
      } else {
        console.error("Unknown AR Mode: ", arMode);
      }
    },

    async showModelViaSceneViewer() {
      this.$refs.quickLookLink.setAttribute(
        "href",
        `intent://arvr.google.com/scene-viewer/1.0?file=${this.href}&mode=ar_only#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;` // eslint-disable-line
      );
      this.$refs.quickLookLink.click();
    },

    async showModelViaQuicklook(asset) {
      const usdzExporter = new THREE.USDZExporter();
      const usdzArraybuffer = await usdzExporter.parse(asset);
      const usdzBlob = new Blob([usdzArraybuffer], {
        type: "application/octet-stream",
      });
      const url = URL.createObjectURL(usdzBlob);

      this.$refs.quickLookLink.setAttribute("href", url);
      this.$refs.quickLookLink.click();
    },

    /**
     * @deprecated
     * @param asset
     */
    showModelViaWebXR(asset) {
      const scene = new THREE.Scene();

      const ambientLight = new THREE.AmbientLight(0xffffff, 1.0);
      scene.add(ambientLight);

      const camera = new THREE.PerspectiveCamera(
        70,
        window.innerWidth / window.innerHeight,
        0.02,
        20
      );

      const geometry = new THREE.PlaneGeometry(100, 100);
      const material = new THREE.MeshBasicMaterial({
        color: 0xffff00,
        side: THREE.DoubleSide,
      });
      const plane = new THREE.Mesh(geometry, material);
      plane.rotation.x = Math.PI * 0.5;
      plane.visible = false;
      asset.add(plane);

      const renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
      });
      renderer.setSize(innerWidth, innerHeight);
      renderer.setPixelRatio(devicePixelRatio);
      renderer.xr.enabled = true;
      renderer.setAnimationLoop(() => {
        if (!renderer.xr.isPresenting) {
          return;
        }

        renderer.render(scene, camera);
      });

      const controller = renderer.xr.getController(0);
      const pointer = new THREE.Vector2();
      const raycaster = new THREE.Raycaster();
      scene.add(controller);

      controller.addEventListener("select", (e) => {
        const { axes } = e.data.gamepad;

        pointer.x = axes[0];
        pointer.y = -axes[1];
        raycaster.setFromCamera(pointer, camera);

        const intersect = raycaster.intersectObject(plane);
        if (intersect[0]) {
          const { x, z } = intersect[0].point;
          asset.position.setX(x);
          asset.position.setZ(z);
        }
      });

      const box3 = new THREE.Box3();
      box3.setFromObject(asset);

      asset.position.setY(Math.abs(box3.min.y));
      asset.position.setZ(-1);
      scene.add(asset);

      const xrManager = renderer.xr;
      xrManager.setReferenceSpaceType("local-floor");

      navigator.xr
        .requestSession("immersive-ar", { requiredFeatures: ["local-floor"] })
        .then((session) => {
          xrManager.setSession(session);
        });
    },
  },
};
</script>

<style>
.quick-look-utils {
  display: none;
}
</style>
