<template>
  <img id="enter-vr-button" src="/images/ui/vr-button.png" @click="onClick()" />
</template>

<script>
import { getState } from "../utils/aframe.utils";
import { sendOnVREnter, sendOnVRExit } from "../utils/dataCollection.utils";
import NoSleep from "nosleep.js";
import { requestDeviceOrientation } from "../../aframe/utils/device-orientation.utils";

export default {
  methods: {
    async onClick() {
      const hasOrientationPermission = await requestDeviceOrientation();
      if (!hasOrientationPermission) {
        return;
      }

      const scene = document.querySelector("a-scene");
      if (getState().isIOS) {
        sendOnVREnter({});
        scene.emit("enter-vr");
        scene.emit("enableCardboardMode");
        new NoSleep().enable();
      } else if (scene.checkHeadsetConnected()) {
        sendOnVREnter({});
        scene.enterVR();
      } else if (!document.fullscreenElement) {
        sendOnVREnter({});
        document.body.requestFullscreen();
      } else {
        sendOnVRExit({});
        document.exitFullscreen();
      }
    },
  },
};
</script>
