import { getState } from "../../vue/utils/aframe.utils";

let promise;
export function requestDeviceOrientation() {
  if (!getState().isIOS) {
    return Promise.resolve(true);
  }

  if (!promise) {
    promise = new Promise((resolve) => {
      try {
        DeviceOrientationEvent.requestPermission().then((permission) => {
          resolve(permission === "granted");
        });
      } catch {
        promise = null;
      }
    });
  }

  return promise;
}
