import { getCanvas } from "../utils/assets.utils";

const textureLoader = new THREE.TextureLoader();
AFRAME.registerComponent("marker-button", {
  schema: {
    content: { type: "string" },
    height: { type: "number", default: 640 },
    width: { type: "number", default: 640 },
  },

  update: function () {
    const { content, height, width } = this.data;
    const { canvas } = getCanvas(`marker-button`);

    canvas.height = height;
    canvas.width = width;

    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);

    context.beginPath();
    context.fillStyle = "rgba(0, 0, 0, 0.75)";
    context.arc(width / 2, height / 2, height / 2.2, 0, 2 * Math.PI);
    context.fill();

    context.beginPath();
    context.strokeStyle = "#f4f4f4";
    context.lineWidth = 30;
    context.arc(width / 2, height / 2, height / 2.3, 0, 2 * Math.PI);
    context.stroke();

    context.beginPath();
    context.textBaseline = "middle";
    context.textAlign = "center";
    context.fillStyle = "white";
    context.font = "220px 'Free Sans Bold'";
    context.fillText(content, width / 2, (height / 2) * 1.05);
    context.fill();

    // this.el.setAttribute('material', 'src', `#${canvas.getAttribute('id')}`);
    const map = textureLoader.load(canvas.toDataURL("jpg"));

    const material = new THREE.SpriteMaterial({
      map,
      transparent: true,
      useScreenCoordinates: false,
    });

    const sprite = new THREE.Sprite(material);
    sprite.scale.set(5, 5, 1);
    this.el.object3D.add(sprite);
  },
});
