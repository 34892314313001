AFRAME.registerComponent("emit-state-event-on-click", {
  events: {
    click: function () {
      if (this.emitting) {
        return;
      }

      this.emitting = true;
      setTimeout(() => (this.emitting = false), 10);

      const { data, event } = this.data;
      this.el.sceneEl.emit(
        event,
        data.startsWith("{") ? JSON.parse(data) : data
      );
    },
  },

  schema: {
    data: { type: "string", default: "" },
    event: { type: "string" },
  },
});
