import DialogBox from "../../vue/components/dialog-box.component.vue";
import {
  addVueComponent,
  removeVueComponent,
  waitForElComponents,
} from "../utils/vue.utils";

const worldPosition = new THREE.Vector3();

AFRAME.registerComponent("desktop-dialog-box", {
  schema: {
    content: { type: "string", default: "B" },
    title: { type: "string", default: "B" },
  },

  init: async function () {
    await waitForElComponents();

    this.onClose = this.onClose.bind(this);

    const dialogBoxEl = addVueComponent(DialogBox, this.data);
    dialogBoxEl.addEventListener("close", this.onClose);
    this.dialogBoxEl = dialogBoxEl;
  },

  update: function () {
    if (!this.dialogBoxEl) {
      setTimeout(() => this.update());
      return;
    }
  },

  remove: function () {
    removeVueComponent(this.dialogBoxEl);
    this.dialogBoxEl.removeEventListener("close", this.onClose);
  },

  tick: function () {
    if (!this.dialogBoxEl) {
      return;
    }

    const vector = this.el.object3D.getWorldPosition(worldPosition);
    // `camera` is a THREE.PerspectiveCamera
    vector.project(this.el.sceneEl.camera);

    if (vector.z < 1) {
      this.dialogBoxEl.style.display = "block";
      this.dialogBoxEl.style.left = `${Math.round(
        (0.5 + vector.x / 2) * window.innerWidth
      )}px`;
      this.dialogBoxEl.style.top = `${Math.round(
        (0.5 - vector.y / 2) * window.innerHeight
      )}px`;
    } else {
      this.dialogBoxEl.style.display = "none";
    }
  },

  onClose: function () {
    this.el.emit("closeDialogBox");
  },
});
