AFRAME.registerSystem("camera-zoom", {
  determineZoom: function (width, height) {
    const { innerHeight, innerWidth } = window;

    let zoom = 1.2575 * (10 / Math.max(width, height));
    if (width < height) {
      const innerAspectRatio = innerHeight / innerWidth;
      const aspectRatio = height / width;
      if (innerAspectRatio > aspectRatio) {
        const diff = width / height - innerWidth / innerHeight;
        zoom -= diff * 1.65;
      }
    } else {
      let innerAspectRatio = innerWidth / innerHeight;
      const aspectRatio = width / height;
      if (innerAspectRatio > aspectRatio) {
        const diff = innerAspectRatio - aspectRatio;
        innerAspectRatio -= diff;
      }

      zoom *= innerAspectRatio;
    }

    return zoom;
  },
});

AFRAME.registerComponent("camera-zoom", {
  schema: {
    animate: { type: "boolean", default: false },
    enabled: { type: "boolean", default: false },
    height: { type: "number", default: 1 },
    width: { type: "number", default: 1 },
    zoomIn: { type: "boolean", default: false },
  },

  init: function () {
    window.addEventListener("resize", () => {
      if (!this.data.enabled) {
        return;
      }

      const { height, width } = this.data;
      this.data.zoomIn = false;
      this.setZoom(this.system.determineZoom(width, height));
    });
  },

  update: function () {
    if (this.data.enabled) {
      this.updateZoom();
    } else {
      this.setZoom(1);
    }
  },

  setZoom: function (zoom) {
    const { animate, zoomIn } = this.data;
    this.el.removeAttribute("animation__zoom");
    if (animate) {
      this.el.setAttribute("animation__zoom", {
        property: "camera.zoom",
        dur: 1500,
        easing: zoomIn ? "easeOutQuad" : "easeInQuad",
        from: zoomIn ? zoom / 2 : null,
        to: zoom,
      });
    } else {
      this.el.setAttribute("camera", "zoom", zoom);
    }
  },

  updateZoom: function () {
    if (!this.data.enabled) {
      return;
    }

    const { height, width } = this.data;
    this.setZoom(this.system.determineZoom(width, height));
  },
});
