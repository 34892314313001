var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "dialog-box dialog-box-vue" }, [
    _c("span", { staticClass: "container" }, [
      _c("h1", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.preview) } }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "close-button", on: { click: _vm.closeDialogBox } },
      [_c("img", { attrs: { src: "/images/ui/exit-button.png" } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }