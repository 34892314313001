AFRAME.registerComponent("emit-on-click", {
  events: {
    click: function () {
      if (this.emitting) {
        return;
      }

      this.emitting = true;
      setTimeout(() => (this.emitting = false), 10);
      this.el.emit(this.data, true);
    },
  },

  schema: { type: "string" },
});
