<template>
  <div class="generic-menu-header-component">
    <div class="container">
      <div class="breadcrumbs">
        <div
          class="mobile-breadcrumbs"
          :class="{ 'show-dots-img': breadcrumbs.length > 1 }"
        >
          <div class="mobile-dots">
            <img
              src="/images/ui/ellipsis.png"
              @click="collapseMenuToggle()"
              class="toggle-btn"
            />
            <img
              src="/images/header/divider.png"
              class="divider"
              :class="{ 'ar-divider': selectedLanguage == 'ar' }"
            />
          </div>
          <ul
            v-if="ListForMobile"
            class="collapse-menu"
            :class="{ 'right-collapse-menu': selectedLanguage == 'ar' }"
          >
            <template v-for="(breadcrumb, index) in breadcrumbs">
              <li
                v-bind:key="'breadcrumb-' + index"
                :class="{ 'hide-last-li': index == breadcrumbs.length - 1 }"
              >
                <span class="scene" @click="navigateBack(breadcrumb)">
                  {{ breadcrumb.title }}</span
                >
              </li>
            </template>
          </ul>
        </div>
        <ul>
          <template v-for="(breadcrumb, index) in breadcrumbs">
            <li
              v-bind:key="'breadcrumb-' + index"
              :class="{ 'hide-on-mobile': index < breadcrumbs.length - 1 }"
            >
              <span class="scene" @click="navigateBack(breadcrumb)">
                {{ breadcrumb.title }}</span
              >
              <img
                src="/images/header/divider.png"
                class="divider"
                :class="{ 'ar-divider': selectedLanguage == 'ar' }"
              />
            </li>
          </template>
        </ul>
      </div>
      <div class="bar" :class="{ 'left-align-bar': selectedLanguage == 'ar' }">
        <div class="icons"></div>
        <div class="title">{{ title }}</div>
        <div class="icons">
          <SceneInformationToggles></SceneInformationToggles>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SceneInformationToggles from "./scene-information-toggles.vue";
import { transitionTo } from "../utils/aframe.utils";
import { getSelectedLanguage } from "../../aframe/utils/local-storage.utils";
import { sendOnClick } from "../utils/dataCollection.utils";

export default {
  components: {
    SceneInformationToggles,
  },
  props: {
    breadcrumbs: { type: Array, default: () => [] },
    title: { type: String },
  },
  data: () => ({
    ListForMobile: false,
    selectedLanguage: getSelectedLanguage(),
  }),
  methods: {
    navigateBack(breadcrumb) {
      sendOnClick({
        contentName: "navlink",
        redirectUrl: `${window.location.origin}/${breadcrumb.scene}`,
      });
      transitionTo(breadcrumb.scene.split("/"));
    },
    collapseMenuToggle() {
      this.ListForMobile = !this.ListForMobile;
    },
  },
};
</script>
<style lang="scss">
.generic-menu-header-component {
  position: fixed;
  top: 9%;
  width: 100vw;
  color: white;
  z-index: 20;
  font-family: "Circular-Loom", Helvetica;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 575px) {
    .breadcrumbs {
      font-size: 12px;
    }

    .title {
      font-size: 16px;
    }
  }
  .container {
    width: 82%;
    max-width: 600px;

    .breadcrumbs {
      ul {
        list-style: none;
        padding: 0 0;
        margin: 10px 0 0 0;

        li {
          font-size: 11px;
          display: inline-flex;
          align-items: center;
          margin-bottom: 5px;
          span {
            &.scene {
              cursor: pointer;
              background: #3a3a3a;
              padding: 8px;
              border-radius: 20px;
              padding: 5px 12px;
              margin: 0 1px;
            }
          }
          img {
            &.divider {
              width: 8px;
              margin: 0 8px;
            }
            &.ar-divider {
              transform: rotate(180deg);
            }
          }
        }
      }
      .mobile-breadcrumbs {
        display: none;
        margin-top: 10px;

        .mobile-dots {
          margin-bottom: 5px;
        }
      }
    }

    .bar {
      display: flex;
      width: 100%;
      padding: 3px 0px;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(52, 52, 52, 1) 55%,
        rgba(0, 0, 0, 1) 100%
      );
      flex-direction: row;
      border-radius: 12px;

      .title {
        font-size: 16px;
        margin: auto;
        text-align: center;
        padding-right: 12px;
      }

      .icons {
        flex: 0.4;
        position: relative;
        right: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .left-align-bar {
      .icons {
        right: 84%;
      }
    }
  }
  @media screen and (max-width: 600px) {
    .container {
      width: 92%;
      .breadcrumbs {
        display: flex;
        align-items: center;
        .show-dots-img {
          display: block !important;
        }
        ul {
          // margin: 5px;
          li {
            &.hide-on-mobile {
              display: none;
            }
          }
        }
        .mobile-breadcrumbs {
          display: none;
          width: 16.5%;

          .mobile-dots {
            display: flex;
            align-items: center;
            img {
              &.toggle-btn {
                cursor: pointer;
                background-color: #3a3a3a;
                border-radius: 20px;
                padding: 7px 8px;
                width: 26%;
                margin: 0 1px;
              }
              &.divider {
                width: 8px;
                margin: 0 8px;
              }
              &.ar-divider {
                transform: rotate(180deg);
              }
            }
          }
          .right-collapse-menu {
            right: 10px !important;
            left: unset !important;
            &:before {
              right: 4px !important;
              left: unset !important;
            }
          }
          .collapse-menu {
            background: #3a3a3a;
            opacity: 0.97;
            position: absolute;
            top: 39%;
            left: 10px;
            display: flex;
            padding: 4% 2%;
            justify-content: space-around;
            flex-direction: column-reverse;
            border-radius: 10px;
            min-width: 10%;
            z-index: 1;
            &:before {
              content: "";
              position: absolute;
              border-style: solid;
              border-width: 0 15px 20px;
              border-color: #3a3a3a transparent;
              display: block;
              width: 0;
              z-index: 1;
              top: -5px;
              left: 7px;
            }
            li {
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 7%;

              &.hide-last-li {
                display: none;
              }
              &:first-child {
                margin: 0 !important;
              }
              span {
                background-color: unset !important;
                padding: 5px 6px;
                margin: 0 !important;
              }
            }
          }
        }
      }
      .bar {
        padding: 6px 0px;
      }
    }
  }
  @media screen and (max-width: 320px) {
    .container {
      .breadcrumbs {
        .mobile-breadcrumbs {
          .collapse-menu {
            top: 42%;
            left: 7px;
          }
          .right-collapse-menu {
            right: 8px !important;
          }
        }
        margin-top: 1%;
        ul {
          li {
            span {
              &.scene {
                margin: 0 14px !important;
              }
            }
          }
        }
      }
      .bar {
        .title {
          font-size: 14px;
        }
      }
    }
  }
  @media screen and (max-width: 827px) and (max-height: 414px) and (orientation: landscape) {
    top: 13%;
  }
}
</style>
