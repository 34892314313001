<template>
  <AssetImage :src="src" class="background"></AssetImage>
</template>

<script>
import AssetImage from "./asset-image.component.vue";

export default {
  components: {
    AssetImage,
  },
  props: {
    src: { type: String },
  },
};
</script>

<style scoped lang="scss">
.background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
}
@media screen and (max-width: 640px) {
  .background {
    object-fit: cover;
  }
}
@media screen and (max-width: 640px) {
  .background {
    object-fit: cover;
  }
}
</style>
