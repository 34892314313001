import { getLookControls } from "../utils/camera.utils";

AFRAME.registerComponent("video-menu-look-selector", {
  events: {
    "raycaster-intersection": function (e) {
      const el = e.detail.els[0];
      const index = el.getAttribute("data-index");
      this.el.parentElement.emit("selectExperience", index);
    },
  },

  tick: function () {
    if (!this.lookControls) {
      this.lookControls = getLookControls();

      if (!this.lookControls) {
        return;
      }
    }

    this.el.object3D.rotation.y = this.lookControls.object3D.rotation.y;
  },
});
