var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "model-annotation-helper" }, [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.content,
          expression: "content",
        },
      ],
      ref: "textarea",
      domProps: { value: _vm.content },
      on: {
        input: function ($event) {
          if ($event.target.composing) return
          _vm.content = $event.target.value
        },
      },
    }),
    _vm._v(" "),
    _c(
      "button",
      {
        on: {
          click: function ($event) {
            return _vm.copy()
          },
        },
      },
      [_vm._v("Copy")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }