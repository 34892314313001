import { getAsset } from "../utils/assets.utils";
import { disableClickable, enableClickable } from "../utils/scene.utils";
import { Endpoints } from "../../endpoints";

AFRAME.registerComponent("video-selector", {
  schema: {
    category: { type: "string" },
    open: { type: "boolean", default: false },
    page: { type: "number", default: 0 },
  },

  events: {
    closeSelector: function () {
      this.el.setAttribute("video-selector", "open", false);
    },

    nextPage: function () {
      this.el.setAttribute("video-selector", "page", this.data.page + 1);
    },

    openSelector: function () {
      this.el.setAttribute(
        "video-selector",
        "open",
        this.el.sceneEl.systems.state.state.userIsInVR
      );
    },

    previousPage: function () {
      this.el.setAttribute("video-selector", "page", this.data.page - 1);
    },
  },

  elExperiences: [],
  elCloseButton: null,
  elNextButton: null,
  elOpenButton: null,
  elPreviousButton: null,
  elWindow: null,

  init: function () {
    this.elExperiences = [];
    this.elCloseButton = this.el.querySelector(".close-selector-button");
    this.elNextButton = this.el.querySelector(".next-page-button");
    this.elOpenButton = this.el.querySelector(".open-selector-button");
    this.elNextButton = this.el.querySelector(".next-page-button");
    this.elPreviousButton = this.el.querySelector(".previous-page-button");
    this.elWindow = this.el.querySelector(".window");
  },

  update: async function () {
    const { category, open, page } = this.data;
    if (!category) {
      return;
    }

    while (this.elExperiences.length > 0) {
      this.el.removeChild(this.elExperiences.pop());
    }

    const data = await this.el.sceneEl.systems["data"].fetch(
      Endpoints.videos(category)
    );
    data.experiences.forEach((experience) => getAsset(experience.thumbnail));
    const experiences = data.experiences.slice(page * 4, page * 4 + 4);

    this.elWindow.setAttribute("visible", open);
    if (!open) {
      disableClickable([
        this.elCloseButton.querySelector(".icon-button"),
        this.elPreviousButton.querySelector(".icon-button"),
        this.elNextButton.querySelector(".icon-button"),
      ]);
      enableClickable(this.elOpenButton.querySelector(".icon-button"));
      return;
    }

    disableClickable(this.elOpenButton.querySelector(".icon-button"));
    enableClickable([
      this.elCloseButton.querySelector(".icon-button"),
      this.elPreviousButton.querySelector(".icon-button"),
      this.elNextButton.querySelector(".icon-button"),
    ]);

    Promise.all(
      experiences.map(async (experience, index) => {
        const element = document.createElement("a-plane");
        element.setAttribute("animation__mouseenter", {
          property: "opacity",
          dur: 300,
          startEvents: "mouseenter",
          to: 0.5,
        });
        element.setAttribute("animation__mouseleave", {
          property: "opacity",
          dur: 300,
          startEvents: "mouseleave",
          to: 1,
        });
        element.setAttribute("color", "black");
        element.setAttribute("data-raycastable-desktop", "");
        element.setAttribute(
          "navigate-to-scene",
          `videos/${category}/${experience.id}`
        );
        element.setAttribute("opacity", 1);
        element.setAttribute("position", {
          x: 0,
          y: 1 - index * 0.65,
          z: 0.01,
        });
        element.setAttribute("scale", { x: 0.9, y: 0.6, z: 1 });
        element.setAttribute("shader", "flat");

        const background = document.createElement("a-plane");
        background.setAttribute("color", "black");
        background.setAttribute("position", { x: 0, y: 0, z: -0.001 });
        background.setAttribute("shader", "flat");

        if (experience.video) {
          background.setAttribute("data-raycastable-vr", "");
          background.setAttribute(
            "navigate-to-scene",
            `videos/${category}/${experience.id}`
          );
        }

        element.appendChild(background);

        if (experience.video) {
          const playIcon = document.createElement("a-plane");
          playIcon.setAttribute("position", { x: 0, y: 0, z: 0.01 });
          playIcon.setAttribute("scale", { x: 0.25, y: 0.25, z: 0.25 });
          playIcon.setAttribute("shader", "flat");
          playIcon.setAttribute("src", "#ui-play-button");
          element.appendChild(playIcon);
        }

        this.el.appendChild(element);

        const asset = await getAsset(experience.thumbnail);
        const assetId = asset.getAttribute("id");
        element.setAttribute("animation__appear", {
          easing: "linear",
          dur: 500,
          property: "components.material.material.color",
          from: "#000",
          to: "#fff",
          type: "color",
        });
        element.setAttribute("color", `#FFF`);
        element.setAttribute("src", `#${assetId}`);

        this.elExperiences.push(element);
      })
    );

    this.elNextButton.setAttribute("visible", page + 1 < data.length / 4);
    this.elPreviousButton.setAttribute("visible", page > 0);
  },
});
