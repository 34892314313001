export function findNearestObject3dByType(object3D, type, parent) {
  if (object3D.type === type) {
    return object3D;
  }

  if (parent) {
    return findNearestObject3dByType(object3D.parent, type, parent);
  }

  let result = null;
  object3D.children.forEach((child) => {
    if (result) {
      return;
    }

    result = findNearestObject3dByType(child, type, parent);
  });

  return result;
}
