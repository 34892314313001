import { clearVuePage, setVuePage } from "../utils/vue.utils";

const defaultTransitionOptions = {
  routeParameters: {},
};

AFRAME.registerSystem("scene", {
  currentScene: null,

  setScene: function (scene, options) {
    options = options
      ? {
          ...defaultTransitionOptions,
          ...options,
        }
      : defaultTransitionOptions;

    const { routeParameters, sceneParameters } = options;

    clearVuePage();
    this.el.sceneEl.emit("setScene", { routeParameters, scene });

    if (options.route.vuePage) {
      this.el.sceneEl.emit("setHasVuePage", true);
      setVuePage(
        options.route.vuePage,
        {
          ...routeParameters,
          ...sceneParameters,
        },
        !!scene
      );
    } else {
      this.el.sceneEl.emit("setHasVuePage", false);
    }
  },
});
