import { getState } from "../../vue/utils/aframe.utils";

AFRAME.registerComponent("canvas-watcher", {
  schema: {
    cardboardMode: { type: "boolean", default: false },
  },

  init: function () {
    this.canvas = this.el.sceneEl.canvas;
    this.sceneEl = this.el.sceneEl;
    this.state = getState();

    window.addEventListener("resize", () => {
      this.sceneEl.resize();
      this.update();
    });
  },

  update: function () {
    const { cardboardMode } = this.data;
    if (!cardboardMode) {
      if (this.mirror) {
        this.mirror.style.display = "none";
      }

      this.canvas.style.width = "100vw";
      document.body.classList.remove("cardboard-mode");
      return;
    }

    document.body.classList.add("cardboard-mode");
    if (!this.mirror) {
      this.mirror = document.createElement("canvas");
      this.mirror.setAttribute("id", "cardboard-mirror");
      this.mirror.style.position = "fixed";
      this.mirror.style.top = "0";
      this.mirror.style.left = "50vw";
      this.mirror.style.height = "100vh";
      this.mirror.style.width = "50vw";
      this.mirror.style.zIndex = "-1";
      this.mirror.style.borderLeft = "white 3px solid";
      document.body.appendChild(this.mirror);

      this.context = this.mirror.getContext("2d");
    }

    this.mirror.style.display = "block";
    this.canvas.style.width = "50vw";
  },

  tock: function () {
    if (!this.data.cardboardMode) {
      return;
    }

    this.mirror.style.left = "50vw";
    this.mirror.width = this.canvas.width;
    this.mirror.height = this.canvas.height;
    this.context.drawImage(this.canvas, 0, 0);
  },
});
