import { config } from "../../vue/helpers/domain-config";

const LOCAL_STORAGE_KEYS = {
  SELECTED_LANGUAGE: "selected-language",
};

export function getSelectedLanguage() {
  return (
    window.localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_LANGUAGE) ||
    config.lang ||
    "en"
  );
}

export function setSelectedLanguage(language) {
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_LANGUAGE, language);
}
