AFRAME.registerComponent("disabled", {
  schema: { type: "boolean", default: true },

  init: function () {
    if (!this.data) {
      return;
    }

    this.setDisabledLook(this.el);
  },

  setDisabledLook: function (el) {
    el.setAttribute("material", {
      color: "#DDD",
      opacity: 0.4,
      transparent: true,
    });

    Array.from(el.children).forEach((child) => this.setDisabledLook(child));
  },
});
