export function getLookControls() {
  return document.querySelector("#camera-look-controls");
}

export function getDesktopCamera() {
  return document.querySelector(".desktop-camera");
}

let orbitControls;
export function getOrbitControls() {
  if (!orbitControls) {
    orbitControls = document.querySelector("[orbit-controls]");
  }
  return orbitControls;
}

export function resetOrbitalCamera() {
  const orbitControls = getOrbitControls();
  orbitControls.removeAttribute("orbit-controls");
  setTimeout(() => {
    orbitControls.setAttribute(
      "orbit-controls",
      "enablePan: false; enableZoom: true; maxPolarAngle: 180;"
    );
  });
}

export function resetCameraRotation() {
  const lookControls = getLookControls();
  lookControls.object3D.rotation.set(0, 0, 0);
}
