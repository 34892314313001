<template>
  <div class="error-dialog">
    <div class="container">
      <img src="/images/settings-menu/avatarico.svg" />
      <h2>Ooops...</h2>
      <p>Something went wrong while loading the scene.</p>
      <button @click="performAction('refresh')">Refresh</button>
      <button @click="performAction('gotoMainMenu')">Go to main menu</button>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    errorSceneController: document.querySelector("[error-scene-controller]"),
  }),
  methods: {
    performAction(event) {
      this.errorSceneController.emit(event);
    },
  },
};
</script>

<style lang="scss">
.error-dialog {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato", "Helvetica Neue";

  .container {
    width: 280px;
    height: 280px;
    position: fixed;
    top: calc(60vh - 250px);

    color: white;
    background-color: black;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 7px;
    padding: 10px 7px;

    img {
      width: 24%;
    }
    p {
      margin: 0;
      font-weight: 100;
      font-size: 15px;
      padding: 0px 15px;
    }
    h2 {
      margin: 0;
      font-size: 17px;
      padding: 10px;
      font-weight: 500;
    }
    @media screen and (height: 480px) {
      margin-top: 6%;
    }
  }
  button {
    background: linear-gradient(
      90deg,
      rgba(45, 239, 224, 1) 0%,
      rgba(45, 155, 239, 1) 57%,
      rgba(53, 237, 251, 1) 100%
    );
    width: 85%;
    border-radius: 60px;
    border: none;
    padding: 14px 0px;
    outline: none;
    margin-top: 4%;
    color: #ffffff;
    font-family: 400;
    font-size: 17px;
  }
  @media screen and (max-width: 600px) {
    .container {
      width: 76%;
      height: 370px;
      padding: 0px 25px;
      border-radius: 12px;

      button {
        width: 90%;
        padding: 19px 0px;
      }
      img {
        width: 30%;
      }
      p {
        padding: 0px 15px 10px 15px;
      }
    }
  }
  @media screen and (max-device-width: 320px) {
    .container {
      height: 390;

      p {
        padding: 0px 15px 7px 15px;
      }
    }
    button {
      padding: 15px 0px;
    }
  }
  @media screen and (max-width: 827px) and (orientation: landscape) {
    position: absolute;
    padding-bottom: 5%;
    .container {
      height: 250px;
      position: absolute;
      margin-top: 16%;
      padding: 24px 0px;

      button {
        padding: 13px 0px;
        margin-top: 3%;
        width: 75%;
      }
      h2 {
        padding: 7px;
      }
    }
  }
  @media screen and (max-width: 668px) and (orientation: landscape) {
    padding-bottom: 7%;

    .container {
      width: 280px;
      margin-top: 21%;
      img {
        width: 20% !important;
      }
      p {
        padding: 0px 15px 4px 15px;
      }
    }
  }
  @media screen and (max-width: 645px) and (orientation: landscape) {
    padding-bottom: 18%;

    .container {
      margin-top: 27%;
    }
  }
}
</style>
