<template>
  <div class="header-component">
    <div class="wrapper">
      <div class="container">
        <div class="menu-back-button">
          <img
            v-if="hasBackLink"
            class="exit"
            @click="navigateBack"
            src="/images/header/backico.png"
          />
        </div>
        <div class="menu-header">
          <img @click="navigateHome" class="bar" :src="logoChange" />
        </div>
        <div class="menu-toggle">
          <img
            :class="{ inactive: !mobileMenuActive, active: mobileMenuActive }"
            src="/images/header/Menu.png"
            class="menu-btn"
            @click="openMenu()"
          />
        </div>
      </div>
    </div>

    <!-- Mobile Menu for displaying all the option -->
    <MobileMenu
      :class="{ visible: mobileMenuActive }"
      class="mobile-menu"
      @close="closeMenu"
    ></MobileMenu>
  </div>
</template>

<script>
import MobileMenu from "./mobile-menu.component.vue";
import { getScene, getState, transitionTo } from "../utils/aframe.utils";
import { isMobioVr } from "../utils/isMobioVr.utils";

export default {
  components: {
    MobileMenu,
  },
  data: () => ({
    hasBackLink: false,
    mobileMenuActive: false,
  }),
  computed: {
    logoChange: function () {
      return isMobioVr
        ? "/images/header/mobiovrlogo.png"
        : "/images/header/logo.svg";
    },
  },
  created() {
    getScene().addEventListener("clearBackLink", (e) => {
      this.hasBackLink = false;
    });
    getScene().addEventListener("setBackLink", (e) => {
      this.hasBackLink = e.data !== null;
    });
  },
  methods: {
    navigateBack() {
      transitionTo(getState().backLink.split("/"));
    },
    navigateHome() {
      transitionTo([""]);
    },
    openMenu() {
      this.mobileMenuActive = true;
      document.body.classList.add("mobile-menu-active");
      const Div = document.createElement("div");
      Div.className = "blurr-bg";
      const Blurrbg = document.querySelectorAll(".blurr-bg");
      if (Blurrbg.length === 0) {
        const x = document.querySelector(".has-vue-page");
        if (x) x.prepend(Div);
      }
    },
    closeMenu() {
      this.mobileMenuActive = false;
      document.body.classList.remove("mobile-menu-active");
      const x = document.querySelector(".blurr-bg");
      if (x) x.remove();
    },
  },
};
</script>
<style lang="scss">
.header-component {
  position: fixed;
  height: 7.5vh;
  width: 100%;
  z-index: 10;

  &.animated {
    transition: 1s;

    .left,
    .right {
      transition: 1s;
      transition-delay: 1.25s;
    }
  }

  &.visible {
    top: 2px;

    .left,
    .right {
      opacity: 1;
    }
  }

  .wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    background: #000000;
    padding: 2px 34px;
    border-radius: 80px;
    max-width: 60%;
    width: 510px;

    .menu-toggle,
    .menu-back-button {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 65%;
      width: 3%;
      img {
        padding: 7px;
        border-radius: 50%;
      }
    }
    .menu-back-button {
      img {
        @media (hover: hover) {
          &:hover {
            background-color: rgba(64, 64, 64, 1);
          }
        }
      }
    }
    .menu-toggle {
      img {
        &:hover {
          background-color: rgba(64, 64, 64, 1);
        }
        &.active {
          background-color: rgba(64, 64, 64, 1);
          cursor: not-allowed;
        }
      }
    }
    .menu-header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24%;
    }
    img {
      height: 100%;
      &.bar {
        cursor: pointer;
        max-width: 500px;
        pointer-events: all;
      }
    }
  }
  .mobile-menu {
    margin-left: 100vw;

    &.visible {
      margin-left: 0;
    }
  }
}
@media screen and (max-width: 1024px) {
  .header-component {
    .container {
      padding: 0px 45px;
      .menu-header {
        height: 22%;
      }
      .menu-back-button {
        img {
          &:active {
            background-color: rgba(64, 64, 64, 1);
          }
          &:not(:active) {
            transition: background-color 0.5s;
          }
        }
      }
    }
    &.visible {
      top: 0;
    }
  }
}
@media screen and (max-width: 640px) {
  .header-component {
    .container {
      max-width: 84%;
      padding: 0px 17px;
      margin: unset;

      .menu-back-button,
      .menu-toggle {
        height: 54%;
        width: 9%;
        img {
          padding: 6px;
        }
      }
      .menu-header {
        width: 80%;
        height: 20%;
      }
    }
    img {
      &.bar {
        margin: 0;
      }
    }
  }
}
@media screen and (max-width: 320px) {
  .header-component {
    .container {
      width: 82% !important;
      padding: 4px 17px;
    }
  }
}
@media screen and (max-width: 900px) and (max-height: 414px) and (orientation: landscape) {
  .header-component {
    .container {
      width: 350px;
      margin-top: 1.7%;
      padding: 8px 30px !important;
      .menu-toggle,
      .menu-back-button {
        height: 76%;
      }
      .menu-header {
        height: 42%;
      }
    }
  }
}
</style>
