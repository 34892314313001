var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "error-dialog" }, [
    _c("div", { staticClass: "container" }, [
      _c("img", { attrs: { src: "/images/settings-menu/avatarico.svg" } }),
      _vm._v(" "),
      _c("h2", [_vm._v("Ooops...")]),
      _vm._v(" "),
      _c("p", [_vm._v("Something went wrong while loading the scene.")]),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.performAction("refresh")
            },
          },
        },
        [_vm._v("Refresh")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.performAction("gotoMainMenu")
            },
          },
        },
        [_vm._v("Go to main menu")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }