var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cardboard-mode-instructions" }, [
    _c("h2", [_vm._v("Rotate your phone and insert it in your cardboard")]),
    _vm._v(" "),
    _c("img", {
      attrs: { src: "/images/ui/exit-button.png" },
      on: {
        click: function ($event) {
          return _vm.exit()
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }