import { mkPageViewData, mkPumaClient } from "puma-client";
import { getSelectedLanguage } from "../../aframe/utils/local-storage.utils";
import { getRockmanId } from "../helpers/auth-helpers";
import { config } from "../helpers/domain-config";
import { getLoginURL } from "./redirect.utils";

const localConfig = {
  session_id: "",
  url: new URL(window.location.origin + "/api/data").href,
  product_name: config.productName,
  subdomain: config.subdomain,
};

let dataConfig = localConfig;
if (window && window.__dc__) dataConfig = window.__dc__;
const dataClient = mkPumaClient(dataConfig);

// PageView Event
const sendPageView = () => {
  const lang = getSelectedLanguage();
  dataClient.sendPageViewData(mkPageViewData(lang), getRockmanId());
};

// Auth Event
const sendOnAuthSuccess = ({ uid, msisdn }) => {
  dataClient.sendInteractionData(
    {
      event_category: "auth",
      event_type: "auth_success",
      args: {
        auth_origin: "same-origin",
        auth_type: uid ? "uid" : "msisdn",
        auth_uid: uid ? uid : undefined,
        msisdn: msisdn ? msisdn : undefined,
        url: window.location.href,
      },
    },
    getRockmanId()
  );
};

const sendOnAuthFail = ({ uid, msisdn, err }) => {
  dataClient.sendInteractionData({
    event_category: "auth",
    event_type: "auth_failure",
    args: {
      auth_origin: "same-origin",
      auth_type: uid ? "uid" : "msisdn",
      auth_uid: uid ? uid : undefined,
      msisdn: msisdn ? msisdn : undefined,
      url: window.location.href,
      redirect_url: `${getLoginURL().href}`,
      system_message: err,
    },
  });
};

// Model Events
const sendOnModelLoad = ({ contentName, contentCategory }) => {
  dataClient.sendInteractionData(
    {
      event_category: "3dmodel",
      event_type: "3dmodel_load",
      args: {
        content_category: contentCategory,
        content_name: contentName,
      },
    },
    getRockmanId()
  );
};

const sendOnModelUnLoad = ({ contentName, contentCategory }) => {
  dataClient.sendInteractionData(
    {
      event_category: "3dmodel",
      event_type: "3dmodel_unload",
      args: {
        content_category: contentCategory,
        content_name: contentName,
      },
    },
    getRockmanId()
  );
};

const sendOnModelLoadError = ({ contentName, contentCategory, err }) => {
  dataClient.sendInteractionData(
    {
      event_category: "3dmodel",
      event_type: "3dmodel_error",
      args: {
        content_category: contentCategory,
        content_name: contentName,
        system_message: err,
      },
    },
    getRockmanId()
  );
};

// Video Events
const sendOnVideoStart = ({ contentName, contentCategory }) => {
  dataClient.sendInteractionData(
    {
      event_category: "video",
      event_type: "video_start",
      args: {
        content_category: contentCategory,
        content_name: contentName,
      },
    },
    getRockmanId()
  );
};

const sendOnVideoClose = ({ contentName, contentCategory, duration }) => {
  dataClient.sendInteractionData(
    {
      event_category: "video",
      event_type: "video_close",
      args: {
        content_category: contentCategory,
        content_name: contentName,
        video_play_duration: duration,
      },
    },
    getRockmanId()
  );
};

const sendOnVideoError = ({ contentName, contentCategory, err }) => {
  dataClient.sendInteractionData(
    {
      event_category: "video",
      event_type: "video_error",
      args: {
        content_category: contentCategory,
        content_name: contentName,
        system_message: err,
      },
    },
    getRockmanId()
  );
};

// VR Mode Event
const sendOnVREnter = ({ contentName, contentCategory }) => {
  dataClient.sendInteractionData(
    {
      event_category: "vr_mode",
      event_type: "vr_mode_enter",
      args: {
        content_category: contentCategory,
        content_name: contentName,
      },
    },
    getRockmanId()
  );
};

const sendOnVRExit = ({ contentName, contentCategory }) => {
  dataClient.sendInteractionData(
    {
      event_category: "vr_mode",
      event_type: "vr_mode_exit",
      args: {
        content_category: contentCategory,
        content_name: contentName,
      },
    },
    getRockmanId()
  );
};

// Annotation Click Event, trigger : "arrow" | "click"
const sendOnAnnotationClick = ({
  contentName,
  contentCategory,
  trigger,
  annotationId,
}) => {
  dataClient.sendInteractionData(
    {
      event_category: "annotation",
      event_type: "annotation_viewed",
      args: {
        content_category: contentCategory,
        content_name: contentName,
        annotation_id: annotationId,
        movement_trigger: trigger,
      },
    },
    getRockmanId()
  );
};

// Click events, contentName: "optout" | "tnc" | "info" | "navlink" | "logout"
const sendOnClick = ({ contentName, redirectUrl }) => {
  dataClient.sendInteractionData(
    {
      event_category: "page",
      event_type: "click",
      args: {
        content_name: contentName,
        url: window.location.href,
        redirect_url: redirectUrl,
      },
    },
    getRockmanId()
  );
};

// 360 Page Movement, trigger: "touch" | "mouse" | "button"
const sendOnPageMovement = ({ trigger }) => {
  dataClient.sendInteractionData(
    {
      event_category: "page",
      event_type: "360_page_movement",
      args: {
        movement_trigger: trigger,
      },
    },
    getRockmanId()
  );
};

export {
  sendPageView,
  sendOnAuthSuccess,
  sendOnAuthFail,
  sendOnModelLoad,
  sendOnModelUnLoad,
  sendOnModelLoadError,
  sendOnVideoStart,
  sendOnVideoClose,
  sendOnVideoError,
  sendOnVREnter,
  sendOnVRExit,
  sendOnClick,
  sendOnAnnotationClick,
  sendOnPageMovement,
};
