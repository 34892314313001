import { transformData } from "../utils/data.utils";
import { errorHandler } from "../utils/error-handler.utils";

AFRAME.registerSystem("data", {
  cache: {},
  promises: {},

  fetch: function (url) {
    if (this.cache[url]) {
      return Promise.resolve(this.cache[url]);
    }

    if (this.promises[url]) {
      return this.promises[url];
    }

    return (this.promises[url] = fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const dataLocalised = transformData(data);
        // TODO: Account for lang when caching
        this.cache[url] = dataLocalised;
        return dataLocalised;
      })).catch((e) => {
      delete this.promises[url];
      errorHandler(e, "data-system-error", "data-system");
    });
  },
});
