var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "main-menu-button",
      class: { "reverse-content": _vm.selectedLanguage == "ar" },
      style: _vm.style,
      on: { click: _vm.onClick },
    },
    [
      _c("div", { staticClass: "text" }, [
        _c("h1", [_vm._v(_vm._s(_vm.title))]),
      ]),
      _vm._v(" "),
      _c("img", {
        staticClass: "icon",
        class: { "ar-icons": _vm.selectedLanguage == "ar" },
        style: _vm.iconStyle,
        attrs: { src: _vm.icon, crossorigin: "" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }