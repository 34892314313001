/**
 * data: [buttonRotation
 */
export function generateButtonMenu(data) {
  let rotation = 0;
  const rotations = [];

  const { buttons, buttonRotation, buttonHeight, buttonWidth, template } = data;

  buttons.forEach(() => {
    rotations.push(rotation);

    if (rotation >= 0) {
      rotation = rotation * -1 - buttonRotation;
    } else {
      rotation *= -1;
    }
  });
  rotations.sort((a, b) => b - a);

  const entities = buttons.map((button, index) => {
    const elEntity = document.createElement("a-entity");

    const { disabled, image, scene, title } = button;
    const elButton = document.createElement("a-entity");
    elButton.setAttribute("data-disabled", disabled ? "true" : "false");
    elButton.setAttribute("data-height", buttonHeight);
    elButton.setAttribute("data-index", index);
    elButton.setAttribute("data-show-loader-text", "true");
    elButton.setAttribute("data-src", image);
    elButton.setAttribute("data-width", buttonWidth);
    elButton.setAttribute("data-title", title);
    elButton.setAttribute("navigate-to-scene", scene);
    elButton.setAttribute("position", "0 0 -4.9");
    elButton.setAttribute("template", "src", template);
    elEntity.setAttribute("rotation", `0 ${rotations[index]} 0`);
    elEntity.appendChild(elButton);

    return elEntity;
  });

  return { entities, rotations };
}
