import { getCanvas } from "../utils/assets.utils";

function hasDoNotAnimateCursor(el) {
  return el.hasAttribute("do-not-animate-cursor");
}

AFRAME.registerComponent("cardboard-ring", {
  schema: {
    progress: { type: "number" },
  },

  events: {
    mouseenter: function (e) {
      if (hasDoNotAnimateCursor(e.detail.intersectedEl)) {
        this.animateRing = false;
      }
    },

    mouseleave: function (e) {
      if (hasDoNotAnimateCursor(e.detail.intersectedEl)) {
        this.animateRing = true;
      }
    },
  },

  init: function () {
    const { canvas } = getCanvas(`cardboard-ring`);
    canvas.width = 512;
    canvas.height = 512;

    const context = canvas.getContext("2d");
    context.beginPath();
    context.strokeStyle = "gray";
    context.lineWidth = 20;
    context.arc(320, 320, 10, 0, 2 * Math.PI);
    context.stroke();
    this.context = context;

    const map = new THREE.Texture(canvas);
    map.needsUpdate = true;
    this.map = map;

    const material = new THREE.SpriteMaterial({
      map,
      transparent: true,
      useScreenCoordinates: false,
    });

    const sprite = new THREE.Sprite(material);
    sprite.scale.set(5, 5, 1);
    this.el.object3D.add(sprite);

    this.animateRing = true;
  },

  update: function () {
    const { context, map } = this;
    const progress = this.animateRing ? this.data.progress : 0;

    context.beginPath();
    context.strokeStyle = "gray";
    context.lineWidth = 25;
    context.arc(320, 320, 120, 0, 2 * Math.PI);
    context.stroke();

    context.beginPath();
    context.strokeStyle = "white";
    context.lineWidth = 25;
    context.arc(320, 320, 120, -0.5 * Math.PI, (-0.5 + progress) * Math.PI);
    context.stroke();

    map.needsUpdate = true;
  },
});
