<template>
  <div id="terms-and-conditions">
    <div v-if="pageText !== null">
      <div class="terms-conditions-head">
        <h1 class="text_1">{{ pageText.text_1 }}</h1>
      </div>
      <div class="terms-condition-container">
        <div class="privacy-policy-container">
          <h1 class="text_2">
            {{ pageText.text_2 }}
            <strong>{{ termsData.companyName }}</strong>
          </h1>
          <p class="text_3">
            {{ pageText.text_3 }}
          </p>
          <br />
          <p class="text_4">
            {{ pageText.text_4 }}
          </p>
        </div>
        <div class="one_line_col">
          <h2 class="text_5" id="owner_of_the_data">{{ pageText.text_5 }}</h2>
          <p>
            {{ termsData.ownerName }}
            <br />
            <br />{{ termsData.ownerAddressLine1 }} <br />{{
              termsData.ownerAddressLine2
            }}
          </p>
          <p>
            <b class="text_6">{{ pageText.text_6 }}</b
            >{{ termsData.owneremail }}
          </p>
        </div>
        <div v-if="isSubdomainZA" class="one_line_col">
          <h2 class="text_7" id="types_of_data">{{ pageText.text_7 }}</h2>
          <p>
            <u>
              <a href="http://n.vr-games.biz/za/tnc-xr-academy?offer=1"
                >http://n.vr-games.biz/za/tnc-xr-academy?offer=1</a
              >
            </u>
          </p>
        </div>
        <div class="one_line_col">
          <h2 class="text_8" id="types_of_data">{{ pageText.text_8 }}</h2>
          <p class="text_9">{{ pageText.text_9 }}</p>
          <br />
          <p class="text_10">{{ pageText.text_10 }}</p>
          <p class="text_11">{{ pageText.text_11 }}</p>
        </div>
        <div class="one_line_col">
          <h2 class="text_12">{{ pageText.text_12 }}</h2>
          <h3 class="text_13">{{ pageText.text_13 }}</h3>
          <p class="text_14">
            {{ pageText.text_14 }}
          </p>
          <h3 class="text_15">{{ pageText.text_15 }}</h3>
          <p class="text_16">
            {{ pageText.text_16 }}
          </p>
          <ul>
            <li class="text_17">
              {{ pageText.text_17 }}
            </li>
            <li class="text_18">
              {{ pageText.text_18 }}
            </li>
            <li class="text_19">
              {{ pageText.text_19 }}
            </li>
            <li class="text_20">
              {{ pageText.text_20 }}
            </li>
            <li class="text_21">
              {{ pageText.text_21 }}
            </li>
          </ul>
          <p class="text_22">
            {{ pageText.text_22 }}
          </p>
          <h3 class="text_23">{{ pageText.text_23 }}</h3>
          <p class="text_24">
            {{ pageText.text_24 }}<br /><br />
            <span class="text_25">
              {{ pageText.text_25 }}
            </span>
          </p>
          <p class="text_26">
            {{ pageText.text_26 }}
          </p>
          <h3 class="text_27">{{ pageText.text_27 }}</h3>
          <p class="text_28">
            {{ pageText.text_28 }}
          </p>
          <p class="text_29">{{ pageText.text_29 }}</p>
          <ul>
            <li class="text_30">
              {{ pageText.text_30 }}
            </li>
            <li class="text_31">
              {{ pageText.text_31 }}
            </li>
          </ul>
          <p class="text_32">
            {{ pageText.text_32 }}
            <br /><br />
            <span class="text_33">
              {{ pageText.text_33 }}
            </span>
          </p>
        </div>
        <div class="one_line_col">
          <h2 id="use_collected_data" class="text_34">
            {{ pageText.text_34 }}
          </h2>
          <p class="text_35">
            {{ pageText.text_35 }}
          </p>
          <p class="text_36">
            {{ pageText.text_36 }}
          </p>
        </div>
        <div class="one_line_col">
          <h2 class="detail_head text_37">
            {{ pageText.text_37 }}
          </h2>
          <p class="text_38">
            {{ pageText.text_38 }}
          </p>
          <ul class="for_boxes">
            <li>
              <div class="box_primary">
                <h3 class="box-head text_39">{{ pageText.text_39 }}</h3>
                <div class="expand-content">
                  <p class="text_40">
                    {{ pageText.text_40 }}
                    <br />
                    <span class="text_41">
                      {{ pageText.text_41 }}
                    </span>
                    <a
                      class="text_42"
                      href="http://optout.networkadvertising.org/?c=1"
                      >{{ pageText.text_42 }}</a
                    >.
                  </p>
                  <p>
                    <strong class="text_43">{{ pageText.text_43 }}</strong
                    >.
                  </p>
                  <h4 class="text_44">
                    {{ pageText.text_44 }}
                  </h4>
                  <div class="wrap">
                    <p class="text_45">
                      {{ pageText.text_45 }}
                    </p>
                    <p class="text_46">
                      {{ pageText.text_46 }}
                      <a
                        class="text_47"
                        href="https://adssettings.google.com/authenticated"
                        >{{ pageText.text_47 }}</a
                      >.
                    </p>
                  </div>
                  <p class="text_48">
                    {{ pageText.text_48 }}
                  </p>
                  <p class="text_49">
                    {{ pageText.text_49 }}
                    <a
                      class="text_50"
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ pageText.text_50 }}</a
                    >
                    –
                    <a
                      class="text_51"
                      href="https://adssettings.google.com/authenticated"
                      >{{ pageText.text_51 }}</a
                    >.
                    <span class="text_52">{{ pageText.text_52 }}.</span>
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div class="box_primary">
                <h3 class="box-head text_53">{{ pageText.text_53 }}</h3>
                <div class="expand-content">
                  <p class="text_54">
                    {{ pageText.text_54 }}
                  </p>
                  <h4 class="text_55">
                    {{ pageText.text_55 }}
                  </h4>
                  <div class="wrap">
                    <p class="text_56">
                      {{ pageText.text_56 }}
                    </p>
                  </div>
                  <p class="text_57">
                    {{ pageText.text_57 }}
                  </p>
                  <p class="text_58">
                    {{ pageText.text_58 }}
                    <a
                      class="text_59"
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ pageText.text_59 }}</a
                    >
                    –
                    <a
                      class="text_60"
                      href="https://tools.google.com/dlpage/gaoptout?hl=en"
                      onclick="return tryFunc('tryGaOptOut',
                    {href:'https://tools.google.com/dlpage/gaoptout?hl=en'})"
                      target="_blank"
                      >{{ pageText.text_60 }}</a
                    >.
                    <span class="text_61"> {{ pageText.text_61 }} </span>
                  </p>
                  <h4 class="text_62">
                    {{ pageText.text_62 }}
                  </h4>
                  <div class="wrap">
                    <p class="text_63">
                      {{ pageText.text_63 }}
                    </p>
                  </div>
                  <p class="text_64">
                    {{ pageText.text_64 }}
                  </p>
                  <h4 class="text_65">
                    {{ pageText.text_65 }}
                  </h4>
                  <div class="wrap">
                    <p class="text_66">
                      {{ pageText.text_66 }}<br />
                      <span class="text_67">
                        {{ pageText.text_67 }}
                      </span>
                      <br />
                      <span class="text_68">
                        {{ pageText.text_68 }}
                      </span>
                    </p>
                  </div>
                  <p class="text_214">
                    {{ pageText.text_214 }}
                  </p>
                  <p class="text_69">
                    {{ pageText.text_69 }}
                    <a
                      class="text_70"
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ pageText.text_70 }}</a
                    >
                    –
                    <a
                      class="text_71"
                      href="https://tools.google.com/dlpage/gaoptout?hl=en"
                      onclick="return tryFunc('tryGaOptOut',
                    {href:'https://tools.google.com/dlpage/gaoptout?hl=en'})"
                      target="_blank"
                      >{{ pageText.text_71 }}</a
                    >.
                    <span class="text_72"> {{ pageText.text_72 }} </span>
                  </p>
                  <h4 class="text_73">
                    {{ pageText.text_73 }}
                  </h4>
                  <div class="wrap">
                    <p class="text_74">
                      {{ pageText.text_74 }}
                    </p>
                  </div>
                  <p class="text_75">
                    {{ pageText.text_75 }}
                  </p>
                  <p class="text_76">
                    {{ pageText.text_76 }}
                    <a
                      class="text_77"
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ pageText.text_77 }}</a
                    >.
                    <span class="text_78"> {{ pageText.text_78 }}</span>
                  </p>
                  <h4 class="text_79">
                    {{ pageText.text_79 }}
                  </h4>
                  <div class="wrap">
                    <p class="text_80">
                      {{ pageText.text_80 }}
                    </p>
                    <p class="text_81">
                      {{ pageText.text_81 }}
                      <a
                        class="text_82"
                        href="https://adssettings.google.com/authenticated"
                        >{{ pageText.text_822 }}</a
                      >.
                    </p>
                  </div>
                  <p class="text_83">
                    {{ pageText.text_83 }}
                  </p>
                  <p class="text_84">
                    {{ pageText.text_84 }}
                    <a
                      class="text_85"
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ pageText.text_85 }}</a
                    >
                    –
                    <a
                      class="text_86"
                      href="https://tools.google.com/dlpage/gaoptout"
                      onclick="javascript:return tryFunc('tryGaOptOut',
                    {href:'https://tools.google.com/dlpage/gaoptout'})"
                      target="_blank"
                      >{{ pageText.text_86 }}</a
                    >.
                    <span class="text_87"> {{ pageText.text_87 }} </span>
                  </p>
                  <h4 class="text_88">
                    {{ pageText.text_88 }}
                  </h4>
                  <div class="wrap">
                    <p class="text_89">
                      {{ pageText.text_89 }}
                    </p>
                    <p class="text_90">
                      {{ pageText.text_90 }}
                      <a
                        class="text_91"
                        href="https://adssettings.google.com/authenticated"
                        >{{ pageText.text_91 }}</a
                      >.
                    </p>
                  </div>
                  <p class="text_92">
                    {{ pageText.text_92 }}
                  </p>
                  <p class="text_93">
                    {{ pageText.text_93 }}
                    <a
                      class="text_94"
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ pageText.text_94 }}</a
                    >
                    –
                    <a
                      class="text_95"
                      href="https://tools.google.com/dlpage/gaoptout"
                      onclick="javascript:return tryFunc('tryGaOptOut',
                    {href:'https://tools.google.com/dlpage/gaoptout'})"
                      target="_blank"
                      >{{ pageText.text_95 }}</a
                    >.
                    <span class="text_96"> {{ pageText.text_96 }} </span>
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div class="box_primary">
                <h3 class="box-head text_97">
                  {{ pageText.text_97 }}
                </h3>
                <div class="expand-content text_98">
                  <p>
                    {{ pageText.text_98 }}
                  </p>
                  <h4 class="text_99">
                    {{ pageText.text_99 }}
                  </h4>
                  <div class="wrap text_100">
                    <p>{{ pageText.text_100 }}</p>

                    <p class="text_101">
                      {{ pageText.text_101 }}
                    </p>
                  </div>
                  <p class="text_102">
                    {{ pageText.text_102 }}
                  </p>
                  <p class="text_103">
                    {{ pageText.text_103 }}
                    <a
                      class="text_104"
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ pageText.text_104 }}</a
                    >.
                    <span class="text_105"> {{ pageText.text_105 }} </span>
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div class="box_primary">
                <h3 class="box-head text_106">
                  {{ pageText.text_106 }}
                </h3>
                <div class="expand-content">
                  <p class="text_107">
                    {{ pageText.text_107 }}
                    <a
                      class="text_108"
                      href="http://optout.networkadvertising.org/?c=1"
                      >{{ pageText.text_108 }}</a
                    >.
                  </p>
                  <p>
                    <strong class="text_109">{{ pageText.text_109 }}</strong
                    >.
                  </p>
                  <h4 class="text_110">
                    {{ pageText.text_110 }}
                  </h4>
                  <div class="wrap">
                    <p class="text_111">
                      {{ pageText.text_111 }}
                    </p>
                  </div>
                  <p class="text_112">
                    {{ pageText.text_112 }}
                  </p>
                  <p class="text_113">
                    {{ pageText.text_113 }}
                    <a
                      class="text_114"
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ pageText.text_114 }}</a
                    >
                    –
                    <a
                      class="text_115"
                      href="https://adssettings.google.com/authenticated#display_optout"
                      >{{ pageText.text_115 }}</a
                    >.
                    <span class="text_116"> {{ pageText.text_116 }} </span>
                  </p>
                  <h4 class="text_117">
                    {{ pageText.text_117 }}
                  </h4>
                  <div class="wrap">
                    <p class="text_118">
                      {{ pageText.text_118 }}
                    </p>
                    <p class="text_119">
                      {{ pageText.text_119 }}
                      <a
                        class="text_120"
                        href="https://adssettings.google.com/authenticated"
                        >{{ pageText.text_120 }}</a
                      >.
                    </p>
                  </div>
                  <p class="text_121">
                    {{ pageText.text_121 }}
                  </p>
                  <p class="text_122">
                    {{ pageText.text_122 }}
                    <a
                      class="text_123"
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ pageText.text_123 }}</a
                    >
                    –
                    <a
                      class="text_124"
                      href="https://adssettings.google.com/authenticated#display_optout"
                      >{{ pageText.text_124 }}</a
                    >.
                    <span class="text_125">{{ pageText.text_125 }} </span>
                  </p>
                  <h4 class="text_126">
                    {{ pageText.text_126 }}
                  </h4>
                  <div class="wrap">
                    <p class="text_127">
                      {{ pageText.text_127 }}
                    </p>
                  </div>
                  <p class="text_128">
                    {{ pageText.text_128 }}
                  </p>
                  <p class="text_129">
                    {{ pageText.text_129 }}
                    <a
                      class="text_130"
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ pageText.text_130 }}</a
                    >
                    –
                    <a
                      class="text_131"
                      href="https://adssettings.google.com/authenticated#display_optout"
                      >{{ pageText.text_131 }}</a
                    >.
                    <span class="text_132"> {{ pageText.text_132 }} </span>
                  </p>
                  <h4 class="text_133">{{ pageText.text_133 }}</h4>
                  <div class="wrap">
                    <p class="text_134">
                      {{ pageText.text_134 }}
                    </p>
                  </div>
                  <p class="text_135">
                    {{ pageText.text_135 }}
                  </p>
                  <p class="text_136">
                    {{ pageText.text_1366 }}
                    <a
                      class="text_137"
                      href="https://www.facebook.com/about/privacy/"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ pageText.text_137 }}</a
                    >
                    –
                    <a
                      class="text_137"
                      href="https://www.aboutads.info/choices/"
                      >{{ pageText.text_138 }}</a
                    >.
                    <span class="text_215"> {{ pageText.text_215 }}</span>
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div class="box_primary">
                <h3 class="box-head text_138">{{ pageText.text_138 }}</h3>
                <div class="expand-content">
                  <p class="text_139">
                    {{ pageText.text_139 }}
                  </p>
                  <h4 class="text_140">
                    {{ pageText.text_140 }}
                  </h4>
                  <div class="wrap">
                    <p class="text_141">
                      {{ pageText.text_141 }}
                    </p>
                  </div>
                  <p class="text_216">{{ pageText.text_216 }}</p>
                  <p class="text_142">
                    {{ pageText.text_142 }}
                    <a
                      class="text_143"
                      href="https://policies.google.com/privacy"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ pageText.text_143 }}</a
                    >.
                    <span class="text_144"> {{ pageText.text_144 }} </span>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="one_line_col">
          <h2 id="further_data text_145">
            {{ pageText.text_145 }}
          </h2>
          <ul class="for_boxes">
            <li>
              <div class="box_primary">
                <h3 class="box-head text_146">
                  {{ pageText.text_146 }}
                </h3>
                <div class="expand-content">
                  <div class="wrap">
                    <p class="text_147">
                      {{ pageText.text_147 }}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="one_line_col">
          <h2 id="rights_subjects text_148">{{ pageText.text_148 }}</h2>
          <p class="text_149">
            {{ pageText.text_149 }}
          </p>
          <p class="text_150">
            {{ pageText.text_150 }}
          </p>
          <ul>
            <li>
              <b class="text_151">{{ pageText.text_151 }} </b>
            </li>
            <li>
              <b class="text_152">{{ pageText.text_152 }}</b>
              <span class="text_213">
                {{ pageText.text_213 }}
              </span>
            </li>
            <li>
              <b class="text_153">{{ pageText.text_153 }}</b>
              <span class="text_154">
                {{ pageText.text_154 }}
              </span>
            </li>
            <li>
              <b class="text_155">{{ pageText.text_155 }}</b>
              <span class="text_156">
                {{ pageText.text_156 }}
              </span>
            </li>
            <li>
              <b class="text_157">{{ pageText.text_157 }}.</b>
              <span class="text_158">
                {{ pageText.text_158 }}
              </span>
            </li>
            <li>
              <b class="text_159">{{ pageText.text_159 }}</b>
              <span class="text_160">
                {{ pageText.text_160 }}
              </span>
            </li>
            <li>
              <b class="text_161">{{ pageText.text_161 }}</b>
              <span class="text_162">
                {{ pageText.text_162 }}
              </span>
            </li>
            <li>
              <b class="text_163">{{ pageText.text_163 }}</b>
              <span class="text_164">
                {{ pageText.text_164 }}
              </span>
            </li>
          </ul>
          <h3 class="text_165">
            {{ pageText.text_165 }}
          </h3>
          <p class="text_166">
            {{ pageText.text_166 }}
          </p>
          <p class="text_167">
            {{ pageText.text_167 }}
          </p>
          <h3 class="text_168">{{ pageText.text_168 }}</h3>
          <p class="text_169">
            {{ pageText.text_169 }}
          </p>
        </div>
        <div class="one_line_col">
          <h2 id="cookie_policy" class="text_170">{{ pageText.text_170 }}</h2>
          <p class="text_171">
            {{ pageText.text_171 }}
            <a
              class="text_172"
              href="https://www.iubenda.com/privacy-policy/gdpr/98937083/cookie-policy"
              title="Cookie Policy"
              >{{ pageText.text_172 }}</a
            >.
          </p>
        </div>
        <div class="one_line_col">
          <h2 id="rights_subjects" class="text_173">{{ pageText.text_173 }}</h2>
          <ul>
            <li>
              <a href="https://skfb.ly/6yRso">"Plant Cell"</a> by t.sullivan is
              licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6YuAv">"Animal Cell"</a> by Roopangi
              Mohta is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/67RFV">"Blue Whale - Textured"</a> by
              Bohdan Lvov is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6RCAu">"Model 58A - House gecko"</a> by
              DigitalLife3D is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/67DEv">
                "Model 20 - Panamanian Golden Frog"
              </a>
              by DigitalLife3D is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6VAJB">
                "Blue Ringed Octopus - Inktober Day 1"
              </a>
              by lesliestowe is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/66T8F"> "Porcupine Fish - 6082" </a> by
              osuecampus is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/o6rQR"> "Château de Bonaguil" </a> by
              pierre391 is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
              .
            </li>

            <li>
              <a href="https://skfb.ly/6QXqU"> "Stonehenge England - VR" </a> by
              GSXNet is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6ZPtG"> "Machu Picchu" </a> by Théo
              Derory is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/SrBn"> "Hard won victory-FT17 Tank" </a>
              by JonTan is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6ZrSS">
                "assignment1: the flying circus"
              </a>
              by MichielBaumann is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6WJxx">
                "Relief of eagle-headed demon"
              </a>
              by Fitzwilliam Museum is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6wWPn"> "Tiglath-Pileser III" </a> by
              danielpett is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>
            <li>
              <a href="https://skfb.ly/6XWIL"> "Byzantine-Medieval Papyrus" </a>
              by Thomas Flynn is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6ZOXZ">
                "Palmyrene Soldiers in Roman Ranks -Trajan Column"
              </a>
              by Gustaw Mackay is licensed under Creative Commons Attribution.
            </li>

            <li>
              <a href="https://skfb.ly/P9CE)">
                "Dancing Ganesha, Brit. Mus. / North India"
              </a>
              by Thomas Flynn is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6YwPH">
                "The Thinker by Auguste Rodin"
              </a>
              by Rigsters is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              "Laocoon and His Sons" () by Rigsters is licensed under Creative
              Commons Attribution.
            </li>

            <li>
              <a href="https://skfb.ly/F9Ho)"> "Venus de Willendorf" </a> by
              Raúl Balsera Moraño is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6nUrC"> "Salvador Dali - Mae West" </a>
              by Deftroy is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6RsFV"> "Mona Lisa Painting" </a> by 3Dee
              is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6YvGQ">
                "Dali,The Persistence of Memory"</a
              >
              by arloopa is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6zTJx">
                "De sterrennacht Nit estelada The Starry Night" </a
              >by dimoni is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6TB7s">
                "Wanderer above the sea of fog"
              </a>
              by betocarrillo is licensed under
              <a href="http://creativecommons.org/licenses/by-sa/4.0/">
                Creative Commons Attribution-ShareAlike.</a
              >
            </li>

            <li>
              <a href="https://skfb.ly/6Snqw"> "The birth of a star" </a> by
              Salvatore Orlando is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution.</a
              >
            </li>

            <li>
              <a href="https://skfb.ly/6yGSx"> "Sun" </a> by SebastianSosnowski
              is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution.
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/PCYW)"> "Blackhole" </a> by rubykamen is
              licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution.
              </a>
            </li>
            <li>
              <a href="https://skfb.ly/6CSSo">
                "Nexus" NASA 1960s super heavy rocket project"
              </a>
              by SebastianSosnowski is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution.
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6RQpN">
                "LUCY | NASA Space Probe | Free Download"
              </a>
              by murilo.kleine is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution.
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6U8BH"> "Earth" </a> by PatelDev is
              licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution.
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6AOGX"> "Mercury" </a> by Anthony Yanez
              is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution.
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6AONM"> "Saturn" </a>by Anthony Yanez is
              licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution.
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6TwFR"> "Venus" </a> by Akshat is
              licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution.</a
              >
            </li>

            <li>
              <a href="https://skfb.ly/6AOOL"> "Uranus" </a> by Anthony Yanez is
              licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution.</a
              >
            </li>

            <li>
              <a href="https://skfb.ly/6AOP9"> "Neptune" </a>by Anthony Yanez is
              licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution.
              </a>
            </li>

            <li>
              <a href="https://skfb.ly/6XNzD">
                "Jabal al-Lawz / Maqla - Mount Sinai in Arabia" </a
              >by Nate Loper 🗺️ ⛏🏺 is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution.
              </a>
            </li>
            <li>
              <a href="https://skfb.ly/6zW68"> "Jupiter" () </a>
              by murilo.kleine is licensed under
              <a href="http://creativecommons.org/licenses/by/4.0/">
                Creative Commons Attribution.
              </a>
            </li>
            <li>
              <a href="https://skfb.ly/6WMUR"> "Saturn V - NASA" () </a>
              by Stanley Creative is licensed under
              <a href="http://creativecommons.org/licenses/by-nd/4.0/">
                Creative Commons Attribution-NoDerivs.
              </a>
            </li>
          </ul>
        </div>

        <div class="one_line_col">
          <h2 id="further_data_processing_info" class="text_174">
            {{ pageText.text_174 }}
          </h2>
          <h3 class="text_175">{{ pageText.text_175 }}</h3>
          <p class="text_176">
            {{ pageText.text_176 }}
          </p>
          <h3 class="text_177">
            {{ pageText.text_177 }}
          </h3>
          <p class="text_178">
            {{ pageText.text_178 }}
          </p>
          <h3 class="text_179">{{ pageText.text_179 }}</h3>
          <p class="text_188">
            {{ pageText.text_188 }}
          </p>
          <h3 class="text_180">{{ pageText.text_180 }}</h3>
          <p class="text_181">
            {{ pageText.text_181 }}
          </p>
          <h3 class="text_182">{{ pageText.text_182 }}</h3>
          <p class="text_183">
            {{ pageText.text_183 }}
          </p>
          <h3 class="text_184">{{ pageText.text_184 }}</h3>
          <p class="text_185">
            {{ pageText.text_185 }}
            <br /><br />
            <span class="text_186">
              {{ pageText.text_186 }}
            </span>
          </p>
        </div>
        <div class="one_line_col">
          <div class="defination-box">
            <h3 id="legal-head" class="text_187">
              {{ pageText.text_187 }}
            </h3>
            <div class="expand-content">
              <h4 class="text_189">{{ pageText.text_189 }}</h4>
              <p class="text_190">
                {{ pageText.text_190 }}
              </p>
              <h4 class="text_191">{{ pageText.text_191 }}</h4>
              <p class="text_192">
                {{ pageText.text_192 }}
              </p>
              <h4 class="text_193">{{ pageText.text_193 }}</h4>
              <p class="text_194">
                {{ pageText.text_194 }}
              </p>
              <h4 class="text_195">{{ pageText.text_195 }}</h4>
              <p class="text_196">
                {{ pageText.text_196 }}
              </p>
              <h4 class="text_197">{{ pageText.text_197 }}</h4>
              <p class="text_198">
                {{ pageText.text_198 }}
              </p>
              <h4 class="text_199">{{ pageText.text_199 }}</h4>
              <p class="text_200">
                {{ pageText.text_200 }}
              </p>
              <h4 class="text_201">{{ pageText.text_201 }}</h4>
              <p class="text_202">
                {{ pageText.text_202 }}
              </p>
              <h4 class="text_203">{{ pageText.text_203 }}</h4>
              <p class="text_204">
                {{ pageText.text_204 }}
              </p>
              <h4 class="text_205">{{ pageText.text_205 }}</h4>
              <p class="text_206">
                {{ pageText.text_206 }}
              </p>
              <h4 class="text_207">{{ pageText.text_207 }}</h4>
              <p class="text_208">
                {{ pageText.text_208 }}
              </p>
              <hr />
              <h4 class="text_209">{{ pageText.text_209 }}</h4>
              <p class="text_210">
                {{ pageText.text_210 }}
              </p>
              <p class="text_211">
                {{ pageText.text_211 }}
              </p>
            </div>
          </div>
        </div>
        <div class="term_footer">
          <p class="text_212">
            {{ pageText.text_212 }} {{ termsData.lastUpdateDate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { waitForLoaderToHide } from "../../aframe/utils/scene.utils";
import { transitionTo, getState } from "../utils/aframe.utils";
import { sendPageView } from "../utils/dataCollection.utils";
import { isMobioVr } from "../utils/isMobioVr.utils";
import { getTermsData, getTermsText } from "../helpers/terms-page-data.helper";
import { config } from "../helpers/domain-config";

export default {
  async created() {
    waitForLoaderToHide();
    this.pageText = await getTermsText();
  },
  async mounted() {
    const scene = document.querySelector("a-scene");
    if (getState().backLink === null) {
      scene.emit("setBackLink", "");
    }
    sendPageView();

    this.pageText = await getTermsText();
  },
  data: () => ({
    mobioVrIsTrue: isMobioVr,
    termsData: getTermsData(),
    pageText: null,
    isSubdomainZA: config.subdomain === "za",
  }),
  methods: {
    goHome() {
      transitionTo([""]);
    },
    goBack() {
      transitionTo(getState().backLink.split("/"));
    },
  },
};
</script>

<style lang="scss">
#terms-and-conditions {
  background-color: #efefef;
  cursor: auto;
  padding: 25px 30px;
  color: #3a3a3a;
  font-family: "Helvetica Neue";

  h1 {
    font-size: 19px;
    font-weight: normal;
    line-height: 23px;
    margin-bottom: 5px;
    color: #3f3f3f;
  }
  .terms-conditions-head {
    margin-top: 5%;
    text-align: center;
  }
  .terms-condition-container {
    margin-top: 4%;
    a {
      text-decoration: none;
      border-bottom: 1px solid #f0f0f0;
      color: #333b43;
    }
    p {
      font-size: 13px;
      font-weight: normal;
      line-height: 19px;
      margin: 0;
      padding: 0;
    }
    .show_comp_link {
      text-decoration: underline;
    }
    .one_line_col {
      border-top: 0;
      padding-bottom: 21px;
      border-bottom: 1px dotted #dfdfdf;

      &:after,
      &:before {
        display: table;
        content: "";
      }
      ul {
        li {
          line-height: 19px;
          font-size: 13px;
          margin-top: 2px;
        }
      }
      h2 {
        font-size: 17px;
        font-weight: bold;
        line-height: 21px;
        padding-top: 21px;
        color: #3f3f3f;
      }
      h3 {
        font-size: 13px;
        line-height: 19px;
        font-weight: bold;
        padding-top: 24px;
        color: #3f3f3f;
        margin: 0;
      }
      strong {
        font-style: inherit;
        font-weight: bold;
      }
      .defination-box {
        margin-top: 21px;
        border: 1px solid #c0c1c1;
        padding: 10px;

        #legal-head {
          border-bottom: 1px dotted #ddd;
          padding: 10px 25px 10px;
          margin: 0;
          margin-top: -11px;
          background-repeat: no-repeat;
          background-position-x: -11px;
          background-color: transparent;
          background-image: url("/images/privacy/bookmark.png");
        }
        .expand-content {
          h4 {
            font-size: 13px;
            font-weight: bold;
            color: #59636d;
          }
        }
      }
      .for_boxes {
        padding: 0;
        display: table;
        content: "";

        li {
          list-style: none;
          width: 48%;
          margin-top: 2%;

          .box_primary {
            padding: 10px;
            border: 1px solid #c0c1c1;

            .box-head {
              background-repeat: no-repeat;
              background-position: 0px 6px;
              background-color: transparent;
              background-image: url("/images/privacy/folder.png");
            }
          }

          h3 {
            border-bottom: 1px dotted #ddd;
            padding: 10px 20px 10px 35px;
            margin: 0px 0px 10px 0px;
          }
          &:nth-child(2n) {
            float: right;
            clear: right;
          }
          &:nth-child(2n + 1) {
            float: left;
            clear: left;
          }
        }
      }
    }
    .term_footer {
      padding: 24px 0px;
    }
  }
}
@media screen and (max-width: 575px) {
  #terms-and-conditions {
    .terms-conditions-head {
      margin-top: 16%;
    }
    .terms-condition-container {
      .one_line_col {
        .for_boxes {
          li {
            width: 100%;
            margin-left: 0;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 568px) and (orientation: landscape) {
  #terms-and-Conditions {
    .terms-conditions-head {
      margin-top: 6%;
    }
  }
}
</style>
