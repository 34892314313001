import { Endpoints } from "../../endpoints";
import { addVueComponent, removeVueComponent } from "../utils/vue.utils";
import GenericMenuHeader from "../../vue/components/generic-menu-header.vue";
import { getScene, getState } from "../../vue/utils/aframe.utils";
import SceneInformation from "../../vue/components/scene-information.vue";
import { getGenericData } from "../../vue/utils/aframe.utils";

async function getSceneData(category, submenu, asset) {
  return await getScene().systems["data"].fetch(
    Endpoints.menus(category, submenu, asset)
  );
}

AFRAME.registerComponent("generic-menu-header", {
  schema: {
    asset: { type: "string" },
    category: { type: "string" },
    enabled: { type: "boolean", default: false },
    submenu: { type: "string" },
    title: { type: "string" },
  },

  init: function () {
    this.onTemplateRendered = this.onTemplateRendered.bind(this);
    this.templateRendered = false;

    this.el.addEventListener("templaterendered", this.onTemplateRendered);
  },

  update: async function () {
    const { asset, category, enabled, submenu } = this.data;

    if (this.vueGenericHeader) {
      removeVueComponent(this.vueGenericHeader);
      this.vueGenericHeader = null;
    }

    if (this.vueSceneInformation) {
      removeVueComponent(this.vueSceneInformation);
      this.vueSceneInformation = null;
    }

    if (!enabled) {
      return;
    }

    if (!this.templateRendered) {
      this.el.setAttribute("template", "src", "#genericMenuHeaderTemplate");
      return;
    }

    const sceneData = await getSceneData(category, submenu, asset);
    const { backScene, introduction, title } = sceneData;
    const didYouKnow = sceneData.did_you_know;

    const breadcrumbs = await this.generateBreadcrumbsData(backScene, []);

    this.elBreadcrumbs.setAttribute("breadcrumbs", {
      scenes: breadcrumbs.map((breadcrumb) => breadcrumb.scene).join(","),
      titles: breadcrumbs.map((breadcrumb) => breadcrumb.title).join(","),
    });
    this.elTitle.setAttribute("app-text", "value", title);
    this.el.sceneEl.emit(
      "setBackLink",
      breadcrumbs[breadcrumbs.length - 1].scene
    );
    this.el.sceneEl.emit("setSceneInformation", {
      didYouKnow,
      introduction,
    });

    this.vueGenericHeader = addVueComponent(GenericMenuHeader, {
      breadcrumbs,
      title,
    });
    this.vueSceneInformation = addVueComponent(SceneInformation);
  },

  async generateBreadcrumbsData(backRoute, result) {
    if (!backRoute) {
      const { homeSceneText } = await getGenericData();
      result.unshift({ scene: "", title: homeSceneText });
      return result;
    }

    const [category, submenu, asset] = backRoute.split("/");

    const { backScene, title } = await getSceneData(category, submenu, asset);
    result.unshift({ scene: backRoute, title });

    return this.generateBreadcrumbsData(backScene, result);
  },

  onTemplateRendered: function () {
    this.templateRendered = true;

    this.elBreadcrumbs = this.el.querySelector(".breadcrumbs");
    this.elBreadcrumbs.setAttribute(
      "position",
      `${getState().selectedLanguage.rtl ? 2.8 : -2.8} .3 0`
    );
    this.elTitle = this.el.querySelector(".title");

    this.update();
  },
});
