AFRAME.registerComponent("invert-cursor-color", {
  events: {
    mouseenter: function () {
      this.el.sceneEl.emit("invertCursorColor", true);
    },
    mouseleave: function () {
      this.el.sceneEl.emit("invertCursorColor", false);
    },
  },
});
