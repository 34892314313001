AFRAME.registerComponent("enter-vr-watcher", {
  events: {
    "enter-vr": function () {
      Array.from(
        document.querySelectorAll("#vue .components, #vue-ui")
      ).forEach((elVue) => elVue.classList.add("user-is-in-vr"));
      this.el.sceneEl.emit("setUserIsInVR", true);
    },
    "exit-vr": function () {
      Array.from(
        document.querySelectorAll("#vue .components, #vue-ui")
      ).forEach((elVue) => elVue.classList.remove("user-is-in-vr"));
      this.el.sceneEl.emit("setUserIsInVR", false);
    },
  },

  init: function () {
    this.el.sceneEl.addEventListener("renderstart", () => {
      this.elVues = Array.from(
        document.querySelectorAll("#vue .components, #vue-ui")
      );
    });
  },
});
