AFRAME.registerSystem("navigate-to-scene", {
  transition: function (commands, navigationOptions) {
    this.el.sceneEl.systems["router"].navigate(commands, navigationOptions);
  },
});

AFRAME.registerComponent("navigate-to-scene", {
  schema: { type: "string" },

  events: {
    click: function () {
      this.system.transition(this.data.split("/"));
    },
  },
});
