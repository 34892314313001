var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "generic-menu-header-component" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "breadcrumbs" }, [
        _c(
          "div",
          {
            staticClass: "mobile-breadcrumbs",
            class: { "show-dots-img": _vm.breadcrumbs.length > 1 },
          },
          [
            _c("div", { staticClass: "mobile-dots" }, [
              _c("img", {
                staticClass: "toggle-btn",
                attrs: { src: "/images/ui/ellipsis.png" },
                on: {
                  click: function ($event) {
                    return _vm.collapseMenuToggle()
                  },
                },
              }),
              _vm._v(" "),
              _c("img", {
                staticClass: "divider",
                class: { "ar-divider": _vm.selectedLanguage == "ar" },
                attrs: { src: "/images/header/divider.png" },
              }),
            ]),
            _vm._v(" "),
            _vm.ListForMobile
              ? _c(
                  "ul",
                  {
                    staticClass: "collapse-menu",
                    class: {
                      "right-collapse-menu": _vm.selectedLanguage == "ar",
                    },
                  },
                  [
                    _vm._l(_vm.breadcrumbs, function (breadcrumb, index) {
                      return [
                        _c(
                          "li",
                          {
                            key: "breadcrumb-" + index,
                            class: {
                              "hide-last-li":
                                index == _vm.breadcrumbs.length - 1,
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "scene",
                                on: {
                                  click: function ($event) {
                                    return _vm.navigateBack(breadcrumb)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(breadcrumb.title)
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "ul",
          [
            _vm._l(_vm.breadcrumbs, function (breadcrumb, index) {
              return [
                _c(
                  "li",
                  {
                    key: "breadcrumb-" + index,
                    class: {
                      "hide-on-mobile": index < _vm.breadcrumbs.length - 1,
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "scene",
                        on: {
                          click: function ($event) {
                            return _vm.navigateBack(breadcrumb)
                          },
                        },
                      },
                      [_vm._v("\n              " + _vm._s(breadcrumb.title))]
                    ),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "divider",
                      class: { "ar-divider": _vm.selectedLanguage == "ar" },
                      attrs: { src: "/images/header/divider.png" },
                    }),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "bar",
          class: { "left-align-bar": _vm.selectedLanguage == "ar" },
        },
        [
          _c("div", { staticClass: "icons" }),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "icons" },
            [_c("SceneInformationToggles")],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }