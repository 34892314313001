import { getState } from "../../vue/utils/aframe.utils";
import { getOrbitControls } from "../utils/camera.utils";
import { CAMERA_CONTROLS } from "../state";

AFRAME.registerComponent("attach-to-active-camera", {
  tick: function () {
    if (getState().userIsInVR) {
      const { vrCameraPosition } = this.el.sceneEl.systems.state.state;
      this.el.object3D.position.set(
        vrCameraPosition.x,
        vrCameraPosition.y,
        vrCameraPosition.z
      );
    } else if (getState().cameraControls === CAMERA_CONTROLS.ORBIT) {
      getOrbitControls().object3D.children[0].getWorldPosition(
        this.el.object3D.position
      );
    } else {
      this.el.object3D.position.copy(this.el.sceneEl.camera.position);
    }
  },
});
