import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://9dd1b119bb2444518c81559b917656c0@o1026448.ingest.sentry.io/5992792",
  integrations: [new Integrations.BrowserTracing()],
  release: "release_1",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// myUndefinedFunction();
