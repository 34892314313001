import { availableMaps } from "../utils/scene.utils";
import { getCanvas } from "../utils/assets.utils";

AFRAME.registerComponent("app-gltf-model", {
  schema: {
    size: { type: "number" },
    src: { type: "model" },
  },

  init: async function () {
    const { size, src } = this.data;

    const response = await fetch(src);
    const arrayBuffer = await response.arrayBuffer();

    const images = [];
    this.model = null;
    const loader = new THREE.GLTFLoader();
    loader.parse(arrayBuffer, null, async (gltfModel) => {
      const scene = gltfModel.scene || gltfModel.scenes[0];
      this.model = scene;
      this.model.animations = gltfModel.animations;
      this.el.setObject3D("mesh", this.model);

      if (size) {
        const { canvas } = getCanvas("app-gltf-model");
        const context = canvas.getContext("2d");
        canvas.width = size;
        canvas.height = size;

        const children = [];
        scene.traverse(async (child) => {
          const { material } = child;

          if (!material) {
            return;
          }

          children.push(child);
        });

        await Promise.all(
          children.map(async (child) => {
            const { material } = child;

            await Promise.all(
              availableMaps
                .filter((map) => !!material[map])
                .map((map) => {
                  return new Promise((resolve) => {
                    const { image } = material[map];
                    const { height, width } = image;
                    if (width <= size && height <= size) {
                      resolve();
                      return;
                    }

                    context.drawImage(image, 0, 0, size, size);

                    if (image.close) {
                      images.push(image);
                    }

                    if (typeof createImageBitmap === "function") {
                      createImageBitmap(canvas).then((image) => {
                        material[map].image = image;
                        resolve();
                      });
                    } else {
                      material[map].image.onload = () => {
                        resolve();
                      };
                      material[map].image.src = canvas.toDataURL("jpg");
                    }
                  });
                })
            );
          })
        );
      }
      images.forEach((image) => image.close());
      this.addModel(scene);
    });
  },

  remove: function () {
    if (!this.model) {
      return;
    }
    this.el.removeObject3D("mesh");
  },

  addModel: function (scene) {
    this.el.object3D.add(scene);
    this.el.emit("model-loaded");
  },
});
