import { getState } from "../../vue/utils/aframe.utils";
import { getOrbitControls } from "../utils/camera.utils";

const vector3 = new THREE.Vector3();
AFRAME.registerComponent("look-at-camera", {
  tick: function () {
    if (getState().userIsInVR) {
      const pos = this.el.sceneEl.systems.state.state.vrCameraPosition;
      vector3.set(pos.x, pos.y + getState().cardboardMode ? 0 : 1.6, pos.z);
      this.el.object3D.lookAt(vector3);
    } else {
      getOrbitControls().object3D.children[0].getWorldQuaternion(
        this.el.object3D.quaternion
      );
    }
  },
});
