import { addVueComponent, removeVueComponent } from "../utils/vue.utils";
import ModelAnnotationHelper from "../../vue/components/model-annotation-helper.component.vue";

const worldPosition = new THREE.Vector3();
AFRAME.registerComponent("model-annotation-helper", {
  events: {
    click: function () {
      this.el.object3D.getWorldPosition(worldPosition);
      this.point.sub(worldPosition);

      this.vueComponent.__vue__.setPoint(this.point);
    },

    "raycaster-intersected": function (e) {
      this.elMarker.setAttribute("visible", "true");
      this.raycaster = e.detail.el;
    },

    "raycaster-intersected-cleared": function () {
      this.elMarker.setAttribute("visible", "false");
      this.raycaster = null;
    },
  },

  init: function () {
    const elMarker = document.createElement("a-entity");
    elMarker.setAttribute("always-on-top", "");
    elMarker.setAttribute("marker-button", "content: !");
    elMarker.setAttribute("scale", "0.04 0.04 0.04");
    elMarker.setAttribute("visible", "false");
    this.el.sceneEl.appendChild(elMarker);

    this.elMarker = elMarker;
    this.vueComponent = addVueComponent(ModelAnnotationHelper);
  },

  remove: function () {
    this.elMarker.parentElement.removeChild(this.elMarker);
    removeVueComponent(this.vueComponent);
  },

  tick: function () {
    if (!this.raycaster) {
      return;
    } // Not intersecting.

    const intersection = this.raycaster.components.raycaster.getIntersection(
      this.el
    );
    if (!intersection) {
      return;
    }
    this.point = intersection.point;
    this.elMarker.setAttribute("position", intersection.point);
  },
});
