<template>
  <div class="toggle-volume">
    <img src="/images/header/volume.png" @click="emit()" />
  </div>
</template>

<script>
export default {
  methods: {
    emit: function () {
      this.$el.dispatchEvent(new CustomEvent("buttonPressed"));
    },
  },
};
</script>

<style lang="scss">
.toggle-volume {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  height: 40px;
  padding: 12px;
  background-color: #0f0c0a;
  border-radius: 50%;

  img {
    height: 100%;
  }
}
</style>
