export const availableMaps = [
  "alphaMap",
  "aoMap",
  "bumpMap",
  "displacementMap",
  "emissiveMap",
  "lightMap",
  "map",
  "metalnessMap",
  "normalMap",
  "roughnessMap",
];

export function disableClickable(elements) {
  if (!Array.isArray(elements)) {
    elements = [elements];
  }

  elements.forEach((el) => {
    el.removeAttribute("data-raycastable");
    el.setAttribute("visible", false);
  });
}

export function disposeObject3D(object3D) {
  if (object3D.geometry) {
    object3D.geometry.dispose();
  }

  if (object3D.material) {
    availableMaps.forEach((map) => {
      if (object3D.material[map]) {
        if (
          object3D.material[map].image &&
          object3D.material[map].image.close
        ) {
          object3D.material[map].image.close();
        }

        object3D.material[map].dispose();
        object3D.material[map] = null;
      }
    });
    object3D.material.dispose();
  }

  object3D.children.forEach((child) => disposeObject3D(child));
}

export function enableClickable(elements) {
  if (!Array.isArray(elements)) {
    elements = [elements];
  }

  elements.forEach((el) => {
    el.setAttribute("data-raycastable", "");
    el.setAttribute("visible", true);
  });
}

export function waitForLoaderToHide() {
  document.querySelector("a-scene").systems["transition"].waitForLoaderToHide();
}
