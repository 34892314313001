<template>
  <div class="previous-next-buttons-component">
    <div class="buttons">
      <img src="/images/ui/left-button.png" @click="moveCamera(-1)" />
      <img
        class="right-button"
        src="/images/ui/left-button.png"
        @click="moveCamera(1)"
      />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    moveCamera: function (change) {
      this.$el.dispatchEvent(
        new CustomEvent("buttonPressed", { detail: change })
      );
    },
  },
};
</script>

<style lang="scss">
@import "include-media/dist/include-media";

.previous-next-buttons-component {
  @include media("<640px") {
    display: none;
  }

  @include media(">640px", "height<=420px") {
    display: none;
  }

  position: fixed;
  bottom: 30px;
  left: 0;
  width: 100%;
  display: block;

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right-button {
    margin-left: 30px;
    transform: scaleX(-1);
  }

  img {
    cursor: pointer;
    width: min(10vh, 50px);
    border: solid 2px white;
    border-radius: 25px;
    transition: 0.5s;
  }
}
</style>
