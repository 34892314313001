import { environmentConfig } from "../environments/environment";

function requireAll(r) {
  r.keys().forEach(r);
}

import "aframe";
import "aframe-environment-component";
import "aframe-look-at-component";
import "aframe-orbit-controls";
import "aframe-render-order-component";
import "aframe-state-component";
import "aframe-template-component";
import "aframe-troika-text";

import "./state.js";
import { LANGUAGES } from "./state";
import { getSelectedLanguage } from "./utils/local-storage.utils";

if (!environmentConfig.production) {
  require("aframe-state-component-devtools");
}

requireAll(require.context("./components/", true, /\.js$/));
requireAll(require.context("./scene-controllers/", true, /\.js$/));
requireAll(require.context("./systems/", true, /\.js$/));

if (LANGUAGES[getSelectedLanguage()].rtl) {
  setTimeout(() => {
    document.body.classList.add("rtl");
  });
}

if (window.localStorage.getItem("cardboard-mode")) {
  const interval = setInterval(() => {
    const button = document.querySelector("#enter-vr-button");

    if (!button) {
      return;
    }

    clearInterval(interval);
    button.click();
  });
}
