<template>
  <div class="model-annotation-helper">
    <textarea ref="textarea" v-model="content"></textarea>
    <button @click="copy()">Copy</button>
  </div>
</template>

<script>
export default {
  data: () => ({
    content: "",
  }),
  methods: {
    copy: function () {
      const { textarea } = this.$refs;
      textarea.select();
      document.execCommand("copy");
    },

    setPoint: function (point) {
      ["x", "y", "z"].forEach(
        (axis) => (point[axis] = Math.floor(point[axis] * 10000) / 10000)
      );
      const { x, y, z } = point;
      this.content = `"position": {\n  "x": ${x},\n  "y": ${y},\n  "z": ${z}\n},\n`;

      setTimeout(() => this.copy());
    },
  },
};
</script>

<style lang="scss">
.model-annotation-helper {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 200px;
  background-color: black;
  z-index: 50;

  textarea {
    width: 100%;
    height: 125px;
  }

  button {
    height: 25px;
    width: 100%;
  }
}
</style>
