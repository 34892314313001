<template>
  <AssetImage :src="src" @click="onClick()" class="carousel-item"></AssetImage>
</template>

<script>
import AssetImage from "./asset-image.component.vue";

export default {
  components: {
    AssetImage,
  },
  props: {
    src: { type: String },
  },
  methods: {
    onClick: function () {
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
.carousel-item {
  display: block;
  max-height: 60vh;
  max-width: 90vw;
  height: auto;
  width: auto;
  margin: auto;
  margin-top: 10vh;

  @media screen and (height: 812px) {
    margin-top: 25vh !important;
  }
}

@media screen and (max-width: 680px), screen and (max-height: 650px) {
  .carousel-item {
    margin-top: 35vh;
    width: 62%;
    object-fit: contain;
  }
}
@media screen and (max-width: 680px) {
  .carousel-item {
    margin-top: 20vh;
    width: 62%;
    object-fit: contain;
  }
}

@media screen and (max-width: 360px) {
  .carousel-item {
    width: 54%;
  }
}
@media screen and (max-width: 900px) and (orientation: landscape) {
  .carousel-item {
    margin-top: 73vh;
  }
}
</style>
