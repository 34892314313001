var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "loader-component" }, [
    _c("div", { staticClass: "container", class: { visible: _vm.visible } }, [
      _c(
        "div",
        {
          staticClass: "loader",
          class: {
            "rotation-90": _vm.rotation === 1,
            "rotation-180": _vm.rotation === 2,
            "rotation-270": _vm.rotation === 3,
          },
        },
        [
          _c("img", {
            staticClass: "center",
            class: { hidden: _vm.cornerVisible },
            attrs: { src: "/images/loader/loader-center.png" },
          }),
          _vm._v(" "),
          _c("img", {
            class: { hidden: !_vm.cornerVisible },
            attrs: { src: "/images/loader/loader-corner.png" },
          }),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text", class: { visible: _vm.showText && _vm.visible } },
      [
        _c("h1", [_vm._v(_vm._s(_vm.loaderText.title))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.loaderText.content))]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }