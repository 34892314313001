var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "scene-information-toggles" }, [
    _c("div", { staticClass: "mobile" }, [
      _vm.hasIntroduction
        ? _c("img", {
            staticClass: "all",
            class: { "all-selected": _vm.sceneInformation.topic === "all" },
            attrs: { src: "/images/ui/arrow-down.png" },
            on: {
              click: function ($event) {
                _vm.recordSceneInfoClickedEvent()
                _vm.toggleSceneInformationMobileView("introduction")
              },
            },
          })
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "desktop" }, [
      _vm.hasDidYouKnow
        ? _c("img", {
            staticClass: "did-you-know",
            attrs: { src: _vm.didYouKnowIcon },
            on: {
              click: function ($event) {
                _vm.recordSceneInfoClickedEvent()
                _vm.toggleTopic("didYouKnow")
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasIntroduction
        ? _c("img", {
            staticClass: "introduction",
            attrs: { src: _vm.introductionIcon },
            on: {
              click: function ($event) {
                _vm.recordSceneInfoClickedEvent()
                _vm.toggleTopic("introduction")
              },
            },
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }