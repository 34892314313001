import { getScene } from "../../vue/utils/aframe.utils";
import {
  sendOnModelLoadError,
  sendOnVideoError,
} from "../../vue/utils/dataCollection.utils";

export function errorHandler(e, message, type) {
  console.error(`Error: ${message}`, e);
  if (type === "video") {
    let errorReason = e;
    // Chrome v60
    if (e.path && e.path[0]) {
      errorReason = e.path[0].error;
    }
    // Firefox v55
    if (e.originalTarget) {
      errorReason = e.originalTarget.error;
    }
    if (errorReason && errorReason.message) errorReason = errorReason.message;
    sendOnVideoError({
      contentCategory: "video",
      err: { err: message, error_reason: errorReason },
    });
  } else if (type === "data-system") {
    sendOnModelLoadError({
      contentCategory: type,
      err: { err: e },
    });
  } else
    sendOnModelLoadError({
      contentCategory: type,
      err: { err: message },
    });
  getScene().systems["router"].navigate(["error"], { updateHistory: false });
}
