AFRAME.registerComponent("always-on-top", {
  schema: {
    beforeRender: { type: "boolean", default: false },
    enabled: { type: "boolean", default: true },
    renderOrder: { type: "number", default: 1 },
  },

  update: function () {
    setTimeout(() => {
      this.setAlwaysOnTop(
        this.el.object3D,
        this.data.enabled,
        this.data.renderOrder
      );
    });
  },

  setAlwaysOnTop: function (object3D, alwaysOnTop, renderOrder) {
    if (object3D.material) {
      object3D.renderOrder = alwaysOnTop ? renderOrder : 0;
      object3D.material.depthTest = !alwaysOnTop;
      object3D.material.depthWrite = !alwaysOnTop;
      object3D.onBeforeRender =
        alwaysOnTop && this.data.beforeRender
          ? this.onBeforeRender.bind(this)
          : () => {};
    }

    object3D.children.forEach((child) =>
      this.setAlwaysOnTop(child, alwaysOnTop, renderOrder + 1)
    );
  },

  onBeforeRender: function (renderer) {
    renderer.clearDepth();
  },
});
