<template>
  <div class="scene-information-toggles">
    <div class="mobile">
      <img
        v-if="hasIntroduction"
        class="all"
        :class="{ 'all-selected': sceneInformation.topic === 'all' }"
        src="/images/ui/arrow-down.png"
        @click="
          recordSceneInfoClickedEvent();
          toggleSceneInformationMobileView('introduction');
        "
      />
    </div>
    <div class="desktop">
      <img
        v-if="hasDidYouKnow"
        class="did-you-know"
        :src="didYouKnowIcon"
        @click="
          recordSceneInfoClickedEvent();
          toggleTopic('didYouKnow');
        "
      />
      <img
        v-if="hasIntroduction"
        class="introduction"
        :src="introductionIcon"
        @click="
          recordSceneInfoClickedEvent();
          toggleTopic('introduction');
        "
      />
    </div>
  </div>
</template>

<script>
import { getScene, getState } from "../utils/aframe.utils";
import { sendOnClick } from "../utils/dataCollection.utils";

export default {
  data: () => ({
    sceneInformation: getState().sceneInformation,
  }),
  computed: {
    didYouKnowIcon: function () {
      return this.getIcon("question-mark-icon", "didYouKnow");
    },
    introductionIcon: function () {
      return this.getIcon("info-icon", "introduction");
    },
    hasDidYouKnow: function () {
      return !!this.sceneInformation.didYouKnow.content;
    },
    hasIntroduction: function () {
      return !!this.sceneInformation.introduction.content;
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    recordSceneInfoClickedEvent() {
      if (getState().sceneInformation.topic === null)
        sendOnClick({ contentName: "info" });
    },
    getIcon(icon, topic) {
      return `/images/ui/${
        topic === getState().sceneInformation.topic ? "exit-button" : icon
      }.png`;
    },

    onResize: function () {
      getScene().emit("disableSceneInformationTopic");
      this.sceneInformation = { ...getState().sceneInformation };
    },

    toggleTopic(topic) {
      getScene().emit("toggleSceneInformationTopic", topic);
      this.sceneInformation = { ...getState().sceneInformation };
    },

    toggleSceneInformationMobileView(topic) {
      // to close submenu independent of state topic
      if (getState().sceneInformation.topic === "didYouKnow") {
        getScene().emit("toggleSceneInformationTopic", "didYouKnow");
      } else {
        getScene().emit("toggleSceneInformationTopic", topic);
      }
      this.sceneInformation = { ...getState().sceneInformation };
      document.querySelector(".all").classList.toggle("rotate-btn");
    },
  },
};
</script>

<style lang="scss">
.scene-information-toggles {
  img {
    height: 22px;
    float: right;
    cursor: pointer;
    margin-right: 10px;
    padding: 5px;
  }

  .all-selected {
    transform: scaleY(-1);
  }

  .mobile {
    @media screen and (max-width: 640px) {
      display: block;
    }

    display: none;
    .rotate-btn {
      transform: rotate(180deg);
    }
  }
  .desktop {
    img {
      @media (hover: hover) {
        &:hover {
          border-radius: 50%;
          background-color: rgba(64, 64, 64, 1);
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .desktop,
    .mobile {
      img {
        &:active {
          border-radius: 50%;
          background-color: rgba(64, 64, 64, 1);
        }
        &:not(:active) {
          transition: background-color 0.5s;
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    img {
      height: 12px;
      margin-right: 16px;
      padding: 8px;
    }
    .desktop {
      display: none;
    }
  }
  @media screen and (max-width: 827px) and (orientation: landscape) {
    img {
      height: 18px;
    }
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
}
</style>
