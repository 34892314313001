var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "previous-next-buttons-component" }, [
    _c("div", { staticClass: "buttons" }, [
      _c("img", {
        attrs: { src: "/images/ui/left-button.png" },
        on: {
          click: function ($event) {
            return _vm.moveCamera(-1)
          },
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "right-button",
        attrs: { src: "/images/ui/left-button.png" },
        on: {
          click: function ($event) {
            return _vm.moveCamera(1)
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }