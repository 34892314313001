import Vue from "vue/dist/vue.js";
import * as VueMoment from "vue-moment";

let currentVuePage;
let elVueComponents;
let elVuePage;
export function addVueComponent(component, propsData, elComponent) {
  if (!elComponent) {
    elComponent = elVueComponents;
  }

  const instance = createVueInstance(component, propsData);
  elComponent.appendChild(instance.$el);

  return instance.$el;
}

export function removeVueComponent(component) {
  component.__vue__.$destroy();
  elVueComponents.removeChild(component);
}

export function setVuePage(page, propsData, hasScene) {
  clearVuePage();
  currentVuePage = createVueInstance(page, propsData).$el;
  elVuePage.appendChild(currentVuePage);

  document.body.classList.add(hasScene ? "has-vue-page" : "only-vue-page");
}

export function clearVuePage() {
  if (currentVuePage) {
    currentVuePage.__vue__.$destroy();
    elVuePage.removeChild(currentVuePage);
    currentVuePage = null;
  }

  document.body.classList.remove("has-vue-page");
  document.body.classList.remove("only-vue-page");
}

function createVueInstance(component, propsData) {
  const Component = Vue.extend(component);
  const instance = new Component({ propsData });
  instance.$mount();
  return instance;
}

export async function waitForElComponents() {
  if (elVueComponents) {
    return Promise.resolve();
  }

  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (elVueComponents) {
        clearInterval(interval);
        resolve();
      }
    }, 1);
  });
}

function init() {
  elVuePage = document.querySelector("#vue .page");
  elVueComponents = document.querySelector("#vue .components");

  if (!elVueComponents) {
    setTimeout(() => init());
    return;
  }

  Vue.use(VueMoment);
}

init();
