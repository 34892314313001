import { render, staticRenderFns } from "./generic-menu.page.vue?vue&type=template&id=b72a7868&scoped=true&"
import script from "./generic-menu.page.vue?vue&type=script&lang=js&"
export * from "./generic-menu.page.vue?vue&type=script&lang=js&"
import style0 from "./generic-menu.page.vue?vue&type=style&index=0&id=b72a7868&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b72a7868",
  null
  
)

export default component.exports