AFRAME.registerComponent("disable-inspector", {
  schema: {
    type: "boolean",
    default: false,
  },

  init: function () {
    if (!this.data) {
      return;
    }

    this.el.removeAttribute("inspector");
  },
});
