<template>
  <div class="dialog-box dialog-box-vue">
    <span class="container">
      <h1>{{ title }}</h1>
      <p v-html="preview"></p>
    </span>
    <div @click="closeDialogBox" class="close-button">
      <img src="/images/ui/exit-button.png" />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    preview: function () {
      // return this.content.split('. ')[0] + '.';
      return this.content;
    },
    text: function () {
      let text = this.content;
      while (text.includes("\\n")) {
        text = text.replace("\\n", "<br />");
      }

      return text;
    },
  },
  props: {
    content: String,
    title: String,
  },
  methods: {
    closeDialogBox: function () {
      this.$el.dispatchEvent(new CustomEvent("close"));
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-box {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 15px;
  color: white;
  transform: translate(calc(-50% + 165px), calc(-50% + 60px));
  padding: 20px;
  z-index: 10;
  width: 260px;
  user-select: none;
  font-family: "Lato", "Helvetica Neue";

  @media screen and (max-width: 640px) {
    transform: translate(-50%, calc(-50% + 100px));
  }

  .container {
    pointer-events: none;
  }

  h1 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }

  .close-button {
    position: absolute;
    top: -14px;
    right: -14px;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    text-align: center;
    background-color: white;
    cursor: pointer;
    color: black;

    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 827px) {
    h1 {
      font-size: 16px;
    }
    p {
      font-size: 13px;
    }
  }
}
</style>
