AFRAME.registerComponent("active-camera-target", {
  schema: {
    userIsInVR: { type: "boolean" },
  },

  tick: function () {
    const { camera } = this.el.sceneEl;
    if (!camera) {
      return;
    }

    this.el.object3D.position.copy(camera.position);

    if (this.data.userIsInVR) {
      this.el.object3D.position.y += 1.6;
    }
  },
});
