var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "carousel",
    {
      ref: "carousel",
      staticClass: "app-carousel",
      attrs: { loop: true, perPage: 1 },
    },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "slide",
        { key: index },
        [
          _c("CarouselItem", {
            attrs: { src: item.image },
            on: {
              click: function ($event) {
                return _vm.navigateTo(item.scene)
              },
            },
          }),
          _vm._v(" "),
          item.title
            ? _c("h1", { staticClass: "card_title" }, [
                _vm._v(_vm._s(item.title)),
              ])
            : _vm._e(),
          _vm._v(" "),
          item.subtitle ? _c("h2", [_vm._v(_vm._s(item.subtitle))]) : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }