import { findNearestObject3dByType } from "../utils/three-js.utils";
import { getState } from "../../vue/utils/aframe.utils";

AFRAME.registerComponent("breadcrumbs", {
  schema: {
    scenes: { type: "array", default: [] },
    titles: { type: "array", default: [] },
  },

  init: function () {
    this.elBreadcrumbs = [];
  },

  update: function () {
    Array.from(this.el.children).forEach((child) => this.el.removeChild(child));
    // this.addBreadcrumb(0, 0);
  },

  addBreadcrumb: function (index, x) {
    const breadcrumb = {
      scene: this.data.scenes[index],
      title: this.data.titles[index],
    };
    const isRtl = getState().selectedLanguage.rtl;

    const elBreadcrumb = document.createElement("a-entity");
    elBreadcrumb.setAttribute("position", { x, y: 0, z: 0 });
    elBreadcrumb.setAttribute("scale", { x: 0.75, y: 0.75, z: 0.75 });
    elBreadcrumb.setAttribute("app-text", {
      anchor: "left",
      value: breadcrumb.title,
    });

    this.el.appendChild(elBreadcrumb);

    const nextInterval = setInterval(() => {
      const text = findNearestObject3dByType(elBreadcrumb.object3D, "Mesh");
      const sizeX = text.geometry.boundingBox.max.x;

      if (Math.abs(sizeX) === Infinity) {
        return;
      }

      let change = 1;
      if (isRtl) {
        change = -1;
        x = x - sizeX;
        elBreadcrumb.setAttribute("position", { x, y: 0, z: 0 });
      }

      clearInterval(nextInterval);

      const elPlane = document.createElement("a-plane");
      elPlane.setAttribute("color", "red");
      elPlane.setAttribute("data-raycastable-vr");
      elPlane.setAttribute(
        "navigate-to-scene",
        breadcrumb.scene ? breadcrumb.scene : ""
      );
      elPlane.setAttribute("position", { x: x + sizeX / 2, y: 0, z: -0.015 });
      elPlane.setAttribute("scale", { x: sizeX, y: 0.3, z: 1 });
      elPlane.setAttribute("visible", false);
      this.el.appendChild(elPlane);

      if (index + 1 < this.data.scenes.length) {
        this.addBreadcrumb(index + 1, x + (sizeX + 0.15) * change);
      }
    });
  },
});
