AFRAME.registerComponent("dialog-box", {
  schema: {
    align: { type: "string", default: "center" },
    attachToVrRig: { type: "boolean", default: true },
    setVisible: { type: "boolean", default: true },
  },

  events: {
    scaleGeometry: function (e) {
      const { height } = e.detail;

      const { setVisible } = this.data;
      if (this.elCloseButton) {
        this.elCloseButton.object3D.position.setY(height * 0.45);
      }

      if (setVisible) {
        this.el.setAttribute("visible", "true");
      }

      this.el.removeAttribute("dialog-box");
    },
  },

  init: function () {
    this.elBackground = this.el.querySelector(".background");
    this.elCloseButton = this.el.querySelector(".close-selector-button");
    this.elDialogBox = this.el.querySelector(".dialog-box");
  },
});
