var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "model-menu" },
    [
      _c("p", [_vm._v(_vm._s(_vm.startExperienceText))]),
      _vm._v(" "),
      _vm._l(_vm.items, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "view-in-3D",
            on: {
              click: function ($event) {
                return _vm.navigateTo(item.scene)
              },
            },
          },
          [
            _c("img", {
              class: { disabled: item.scene === null },
              attrs: { src: item.image, crossorigin: "" },
            }),
            _vm._v(" "),
            item.title ? _c("h1", [_vm._v(_vm._s(item.title))]) : _vm._e(),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }