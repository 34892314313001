AFRAME.registerComponent("orbit-controls-fix", {
  update: function () {
    const orbitControls = this.el.components["orbit-controls"];

    if (!orbitControls) {
      console.error(
        "Orbit controls not found. Unable to disable enterVR and exitVR functions."
      );
      return;
    }

    this.el.sceneEl.removeEventListener("enter-vr", orbitControls.onEnterVR);
    this.el.sceneEl.removeEventListener("exit-vr", orbitControls.onExitVR);
  },
});
