var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AssetImage", {
    staticClass: "carousel-item",
    attrs: { src: _vm.src },
    on: {
      click: function ($event) {
        return _vm.onClick()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }